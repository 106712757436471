import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTermsContent } from '../reduxToolKit/slices/staticContentSlice.js/termsApiSlice'

const TermsCondition = () => {
    const dispatch = useDispatch();
    const {termsData} = useSelector((state)=> state.termsReducer)


    useEffect(()=> {

        dispatch(fetchTermsContent())

    },[])
  return (
    <>
    <Header />
    <section>
        <div class="privacy-area">
            <figure>
                <img src="images/privacy-banner.png" alt="" />
            </figure>
            {/* <figcaption>Term & Conditions</figcaption> */}

            <div class="container">
            <h4>Terms and condition</h4>
            <p
            dangerouslySetInnerHTML={{
              __html: termsData?.data?.[0]?.description,
            }}
            className="editor"
          ></p>             </div>
        </div>
    </section>
    

    <Footer />

    </>
  )
}

export default TermsCondition