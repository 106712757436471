import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap-button-loader";

import { ToastContainer, toast } from "react-toastify";
import { fetchPgDetails } from "../reduxToolKit/slices/pgSlices/pgDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { createPgApi } from "../reduxToolKit/slices/pgSlices/addPgAddSlice";
import moment from "moment/moment";

const initialState = {
  availableFor: "",
  availableFrom: "",
  noticePeriod: true,
  noticePeriodDuration: "",
  // maintenanceAmount: true,
  // maintenanceAmountValue: "",
  foodIncluded: true,

  description: "",
  startTime: "",
  endTime: "",
  gateClosing: true,
  gateClosingTime: "",
  availableFrom: "",
  preferredGuest: "",

  includedMeals: [],
  errorMsg: {},
};
const EditProperty3 = () => {
  const [isLoading, setLoading] = useState(false);

  const [iState, updateState] = useState(initialState);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pgDetails } = useSelector((state) => state.pgData);

  const [rules, setRules] = useState([]);

  const [pgHostelRule, setPgHostelRule] = useState([

    {
      id: 2,
      name: "Non-Veg Allowed",
      value: "Non-Veg allowed",

      isSelected: false,
    },
    {
      id: 3,
      name: "Opposite gender Allowed",
      value: "Opposite gender allowed",

      isSelected: false,
    },
    {
      id: 4,
      name: "Smoking Allowed",
      value: "Smoking not allowed",

      isSelected: false,
    },
    {
      id: 5,
      name: "Drink Allowed",
      value: "Drink not allowed",

      isSelected: false,
    },
  
    {
      id: 7,
      name: "Party Allowed",
      value: "Party allowed",

      isSelected: false,
    },
    {
      id: 8,
      name: "Rent Lock-In",
      value:"Rent lock-in",
      isSelected: false,
    },
    {
      id: 9,
      name:'Guardian Stay Allowed',
      value: "No guardians stay",
      isSelected: false,
    },
  ]);

  const {
    availableFor,
    availableFrom,
    noticePeriod,
    noticePeriodDuration,
    // maintenanceAmount,
    // maintenanceAmountValue,
    foodIncluded,
    description,
    includedMeals,
    gateClosingTime,
    gateClosing,
    preferredGuest,
    errorMsg,
  } = iState;

  const handleRadio = (e, setter, prop) => {
    updateState({ ...iState, [setter]: prop });
  };

  const handleCheckbox = (e, getter, prop) => {
    const { checked, name } = e.target;

    if (checked) {
      updateState({ ...iState, [name]: [...getter, prop] });
    } else {
      let tempArray = getter;
      let index = tempArray.findIndex((item) => item == prop);
      tempArray.splice(index, 1);

      updateState({ ...iState, [name]: tempArray });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  useEffect(() => {
    dispatch(fetchPgDetails());
  }, []);

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (!availableFor) {
      flag = false;
      errorMsg.availableFor = " Place available  field is required";
    }

    if (!availableFrom) {
      flag = false;
      errorMsg.availableFrom = " Available from  field is required";
    }

    // if (maintenanceAmount) {
    //   if (!maintenanceAmountValue) {
    //     flag = false;
    //     errorMsg.maintenanceAmountValue =
    //       " Maintenance Amount   field is required";
    //   }
    // }

    if (noticePeriod) {
      if (!noticePeriodDuration) {
        flag = false;
        errorMsg.noticePeriodDuration = " Notice period field is required";
      }
    }

    if (foodIncluded) {


    if (includedMeals?.length == 0) {
      flag = false;
      errorMsg.includedMeals = " Included meal field is required";
    }}



    if (rules?.length == 0) {
      flag = false;
      errorMsg.rules = " PG rules field is required";
    }

    if (!preferredGuest) {
      flag = false;
      errorMsg.preferredGuest = " Preferred Guest field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = handleValidation();
    if (isValid) {
      setLoading(true)

      let data = {
        pgHostelRule: rules,
        availableFor,
        availableFrom,
        noticePeriod,
        // maintenanceAmount,
        
        foodIncluded,
        description,
        
        
        gateClosing,
        preferredGuest,
      };
      if(noticePeriod)
      {
        data["noticePeriodDuration"]=noticePeriodDuration.toString()
      }
      if(foodIncluded)
      
        {
          data["includedMeals"]=includedMeals
        }
        // if(maintenanceAmount)
          
      
        //   {
        //     data["maintenanceAmountValue"]=maintenanceAmountValue
        //   }
          if(gateClosing)
          
      
            {
              data["gateClosingTime"]=gateClosingTime
            }
      dispatch(createPgApi(data))
        .then((res) => {
          if (res.payload.success) {

            setLoading(false)
              navigate("/edit-property-4");

            toast.success(res.message);
          } else {
            toast.error(res?.payload?.response?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.payload?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    updateState({
      ...iState,

      availableFor: pgDetails?.data?.[0]?.availableFor
        ? pgDetails?.data?.[0]?.availableFor
        : "",
      availableFrom: pgDetails?.data?.[0]?.availableFrom
        ? pgDetails?.data?.[0]?.availableFrom
        : "",
      noticePeriod: pgDetails?.data?.[0]?.noticePeriod
        ? pgDetails?.data?.[0]?.noticePeriod
        : false,
      noticePeriodDuration: pgDetails?.data?.[0]?.noticePeriodDuration
        ? pgDetails?.data?.[0]?.noticePeriodDuration
        : "",
      // maintenanceAmount: pgDetails?.data?.[0]?.maintenanceAmount
      //   ? pgDetails?.data?.[0]?.maintenanceAmount
      //   : true,
      // maintenanceAmountValue: pgDetails?.data?.[0]?.maintenanceAmountValue
      //   ? pgDetails?.data?.[0]?.maintenanceAmountValue
      //   : "",
      foodIncluded: pgDetails?.data?.[0]?.foodIncluded
        ? pgDetails?.data?.[0]?.foodIncluded
        : false,
      description: pgDetails?.data?.[0]?.description
        ? pgDetails?.data?.[0]?.description
        : "",
      includedMeals: pgDetails?.data?.[0]?.includedMeals
        ? pgDetails?.data?.[0]?.includedMeals
        : [],
      gateClosingTime: pgDetails?.data?.[0]?.gateClosingTime
        ? pgDetails?.data?.[0]?.gateClosingTime
        : "",
      gateClosing: pgDetails?.data?.[0]?.gateClosing
        ? pgDetails?.data?.[0]?.gateClosing
        : false,
      preferredGuest: pgDetails?.data?.[0]?.preferredGuest
        ? pgDetails?.data?.[0]?.preferredGuest
        : "",
    });

    setRules(
      pgDetails?.data?.[0]?.pgHostelRule
        ? pgDetails?.data?.[0]?.pgHostelRule
        : []
    );
  }, [pgDetails]);


  const handleaddRule = (rule) => {
    let tempArray = [...rules];

    tempArray.push(rule);

    setRules(tempArray);
  };

  const handleRemoveRule = (rule) => {
    let tempArray = [...rules];

    let index = tempArray?.findIndex((ele) => ele == rule);

    tempArray.splice(index, 1);

    setRules(tempArray);
  };
  return (
    <>
      <ToastContainer />

      <Header />
      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="Previous">
                Step 1 <span>Room Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>
              </li>
              <li class="Previous">
                Step 2 <span>Location Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>{" "}
                </h6>{" "}
              </li>
              <li class="active">
                Step 3 <span>PG Details</span>{" "}
              </li>
              <li>
                Step 4 <span>Amenities Details</span>{" "}
              </li>
              <li>
                Step 5 <span>Gallery</span>{" "}
              </li>
              <li>
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="EditPropertyBox">
            <h3 class="Title">Provide Details of PG</h3>
            <div class="EditPropertyForm">
              <form>
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-8 col-12">
                    <div class="form-group">
                      <h6>
                        Place is available for <span class="required">*</span>
                      </h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Boys
                            <input
                              type="radio"
                              name="availableFor"
                              checked={availableFor == "Boys" ? true : false}
                              onChange={(e) =>
                                handleRadio(e, "availableFor", "Boys")
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            Girls
                            <input
                              checked={availableFor == "Girls" ? true : false}
                              type="radio"
                              name="availableFor"
                              onChange={(e) =>
                                handleRadio(e, "availableFor", "Girls")
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            Co-living
                            <input
                              type="radio"
                              checked={
                                availableFor == "Co-living" ? true : false
                              }
                              onChange={(e) =>
                                handleRadio(e, "availableFor", "Co-living")
                              }
                              name="availableFor"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errorMsg.availableFor && !availableFor && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.availableFor}
                        </p>
                      )}
                    </div>
                    <div class="form-group">
                      <h6>
                        Available From <span class="required">*</span>
                      </h6>
                      <input
                        type="date"
                        class="form-control"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        max="9999-12-31"  
                        value={moment(availableFrom)?.format("YYYY-MM-DD")}
                        name="availableFrom"
                        onChange={handleChange}
                      />
                    </div>
                    {errorMsg.availableFrom && !availableFrom && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.availableFrom}
                      </p>
                    )}
                    {/* <div class="form-group">
                      <h6>
                        Maintenance Amount <span class="required">*</span>
                      </h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              checked={maintenanceAmount}
                              onChange={(e) =>
                                handleRadio(e, "maintenanceAmount", true)
                              }
                              type="radio"
                              name="maintenanceAmount"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              checked={!maintenanceAmount}
                              type="radio"
                              onChange={(e) =>
                                handleRadio(e, "maintenanceAmount", false)
                              }
                              name="maintenanceAmount"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>

                    {maintenanceAmount && (
                      <div class="form-group">
                        <h6>
                          Maintenance Amount <span class="required">*</span>
                        </h6>
                        <input
                          type="text"
                          value={maintenanceAmountValue}
                          onChange={(e) => {
                            if (
                              !/^[0-9]{0,10}$/.test(e.target.value) ||
                              e.target.value == "e"
                            )
                              return;

                            {
                              updateState((prev) => ({
                                ...prev,
                                maintenanceAmountValue: e.target.value,
                              }));
                            }
                          }}
                          class="form-control"
                          placeholder="Enter Maintenance Amount"
                        />
                        {errorMsg.maintenanceAmountValue &&
                          !maintenanceAmountValue && (
                            <p className="error-msg" style={{ color: "red" }}>
                              {errorMsg.maintenanceAmountValue}
                            </p>
                          )}
                      </div>
                    )} */}
                  </div>
                  <div class="col-lg-4 offset-lg-2 col-md-6 col-sm-8 col-12">
                    <div class="form-group">
                      <h6>Notice Period </h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              checked={noticePeriod}
                              type="radio"
                              onChange={(e) =>
                                handleRadio(e, "noticePeriod", true)
                              }
                              name="noticePeriod"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              checked={!noticePeriod}
                              type="radio"
                              onChange={(e) =>
                                handleRadio(e, "noticePeriod", false)
                              }
                              name="noticePeriod"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    {noticePeriod &&
                    <div class="form-group">
                      <h6>
                        Notice Period <span class="required">*</span>
                      </h6>
                      <select
                        value={noticePeriodDuration}
                        name="noticePeriodDuration"
                        onChange={handleChange}
                        class="form-control"
                      >
                        <option value={""}>Select</option>

                        {/* <option value={"15"}>15 days</option>
                        <option value={"30"}>1 Months</option> */}
                        <option value={"15"}>15 days</option>
                        <option value={"30"}>1 Month</option>
                        <option value={"60"}>2 Months</option>
                      </select>

                      {errorMsg.noticePeriodDuration &&
                        !noticePeriodDuration && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.noticePeriodDuration}
                          </p>
                        )}
                    </div>}
                    <div class="form-group">
                      <h6>Food Included</h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              checked={foodIncluded}
                              type="radio"
                              name="foodIncluded"
                              onChange={(e) =>
                                handleRadio(e, "foodIncluded", true)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              checked={!foodIncluded}
                              type="radio"
                              name="foodIncluded"
                              onChange={(e) =>
                                handleRadio(e, "foodIncluded", false)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    {foodIncluded &&
                    <div class="form-group">
                      <h6>Included Meals</h6>
                      <ul>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Breakfast
                            <input
                              checked={includedMeals?.includes("Breakfast")}
                              type="checkbox"
                              name="includedMeals"
                              onChange={(e) =>
                                handleCheckbox(e, includedMeals, "Breakfast")
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Lunch
                            <input
                              checked={includedMeals?.includes("Lunch")}
                              type="checkbox"
                              name="includedMeals"
                              onChange={(e) =>
                                handleCheckbox(e, includedMeals, "Lunch")
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Dinner
                            <input
                              checked={includedMeals?.includes("Dinner")}
                              type="checkbox"
                              name="includedMeals"
                              onChange={(e) =>
                                handleCheckbox(e, includedMeals, "Dinner")
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>

                      {errorMsg.includedMeals && includedMeals?.length == 0 && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.includedMeals}
                        </p>
                      )}
                    </div>}
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <h6>PG Rules</h6>
                      <ul class="Extra">
                        {pgHostelRule?.map((rule, i) => (
                          <li>
                            <label class="CheckBox">
                              {" "}
                              {rule?.name}
                              <input
                                type="checkbox"
                                name="pgHostelRule"
                                checked={rules?.includes(rule?.value)}
                                onChange={(e) => {
                                  let tempArray = [...pgHostelRule];
                                  let obj = tempArray[i];
                                  if (e.target.checked) {
                                    handleaddRule(rule?.value);

                                    obj.isSelected = true;
                                  } else {
                                    obj.isSelected = false;
                                    handleRemoveRule(rule?.value);
                                  }
                                  tempArray[i] = obj;
                                  setPgHostelRule(tempArray);
                                }}
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>

                      {errorMsg.rules && rules?.length == 0 && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.rules}
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="form-group">
                      <h6>Gate Closing Time</h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              checked={gateClosing}
                              onChange={(e) =>
                                handleRadio(e, "gateClosing", true)
                              }
                              type="radio"
                              name="gateClosing"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              checked={!gateClosing}
                              onChange={(e) =>
                                handleRadio(e, "gateClosing", false)
                              }
                              type="radio"
                              name="gateClosing"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    {gateClosing && (
                      <div class="form-group">
                        <h6>Gate Closing Time</h6>
                        <input
                          type="time"
                          class="form-control"
                          value={gateClosingTime}
                          name="gateClosingTime"
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>
                  <div class="col-lg-4 offset-lg-2 col-md-6 col-sm-12 col-12">
                    <div class="form-group">
                      <h6>
                        Preferred Guest <span class="required">*</span>
                      </h6>
                      <select
                        value={preferredGuest}
                        name="preferredGuest"
                        onChange={handleChange}
                        class="form-control"
                      >
                        <option value={""}>Select</option>
                        <option value={"Students"}>Students</option>
                        <option value={"Working Professional"}>
                          Working Professional
                        </option>
                        <option value={"Both"}>Both</option>
                      </select>

                      {errorMsg.preferredGuest && !preferredGuest && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.preferredGuest}
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group mb-0">
                      <h6>Description</h6>
                      <textarea
                        onChange={handleChange}
                        value={description}
                        name="description"
                        class="form-control"
                        id="editor"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="Buttons">
            <Link to="/edit-property-2" class="PreviousBtn">
              Previous
            </Link>
            <Button
                  loading={isLoading} onClick={handleSubmit} class="SaveContinueBtn">
              Save &amp; Continue
            </Button>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default EditProperty3;
