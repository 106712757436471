
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"


export const fetchFlatDetails = createAsyncThunk('FlatDetails/fetchFlatDetails', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`${url}user/flat/get`, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;
});

const initialState = {
    flatDetails: {},
    status: 'idle',
    error: null,
}
const fetchFlatDetailsSlice = createSlice({
    name: 'flatListingData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlatDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFlatDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.flatDetails = action.payload;
            })
            .addCase(fetchFlatDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default fetchFlatDetailsSlice.reducer;
