
import {  createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"



export const raiseTicketApi = createAsyncThunk('Support/raiseTicket', async (payload) => {

    try{

    const response = await axios.post(`${url}user/raiseTicket`,payload, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;

}
catch(err){

    return err;


}
});