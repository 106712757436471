import React, { useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTicket } from '../../reduxToolKit/slices/supportSlice/getTicketSlice'

const MyTicketSupport = () => {

    const dispatch =  useDispatch()

    const {ticketData} = useSelector((state)=> state.ticketReducer)


    useEffect(()=>{


        dispatch(fetchTicket())

    },[])



  return (
    <>

    <Header />


<section>
    <div class="my-proprties">
        <div class="right-search-area">
            <div class="activity-search">
             <div class="activity-search-tab">
                 <div class="activity-search-left">
                     <h3>My Tickets</h3>                        
                 </div>
             </div>

             <div class="search-body">
                 <a href="my-ticket-view.html">
                    </a><div class="my-tkt"><a href="my-ticket-view.html">
                        <p>Support ID: <strong>#43857945</strong></p>
                        <p>You are a: <strong>Tenant</strong></p>
                        <p>Select issue you are facing from list: <strong>Difficulty in posting property</strong></p>a
                        </a><p><a href="my-ticket-view.html">Describe your concern : <strong>Reats include a glass of French champagne, parking and a late checkout. Gym included. Flexible cancellation applies </strong></a><strong><a href="#">Read More</a>...</strong></p>
                     </div>
                     <span class="red-tkt">Inprogress</span>
                 
             </div>

             <div class="search-body">
                 <div class="my-tkt">
                   <a href="my-ticket-view.html">
                    <p>Support ID: <strong>#43857945</strong></p>
                    <p>You are a: <strong>Tenant</strong></p>
                    <p>Select issue you are facing from list: <strong>Difficulty in posting property</strong></p>
                    </a><p><a href="my-ticket-view.html">Describe your concern : <strong>Reats include a glass of French champagne, parking and a late checkout. Gym included. Flexible cancellation applies </strong></a><strong><a href="#">Read More</a>...</strong></p>
                 </div>
                 <span class="yellow-tkt">Under Review</span>
                   
             </div>

             <div class="search-body">
                 <a href="my-ticket-view.html">
                    </a><div class="my-tkt"><a href="my-ticket-view.html">
                        <p>Support ID: <strong>#43857945</strong></p>
                        <p>You are a: <strong>Tenant</strong></p>
                        <p>Select issue you are facing from list: <strong>Difficulty in posting property</strong></p>
                        </a><p><a href="my-ticket-view.html">Describe your concern : <strong>Reats include a glass of French champagne, parking and a late checkout. Gym included. Flexible cancellation applies </strong></a><strong><a href="#">Read More</a>...</strong></p>
                     </div>
                     <span class="green-tkt">Active</span>
                 
             </div>
            </div>                        
         </div>
    </div>
   </section>

   <Footer />
      
    </>
  )
}

export default MyTicketSupport
