import React, { lazy, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { toast } from "react-toastify";

const AutoPlace = ({ updateState, iState, address, cityArray, setCityArray , searchAddress,setSearchAddress ,selectCity}) => {
  const [tempState, setTempState] = useState("");
  


  const handleChange = (address) => {
    updateState((pre) => ({ ...pre, address }));
    setTempState(address);
  };

  const handleSelect = (address) => {
    if(address.includes(selectCity) && !searchAddress.includes(address)){
    geocodeByAddress(address)
      .then((results) => {
        let tempAddress = extractAddressComponents(
          results?.[0]?.address_components
        );

        return { tempAddress, address, results };
      })
      .then((latLng) => {
        getLatLng(latLng.results[0]).then((res) => {
          updateState((prev) => ({ ...prev, lat: res.lat, long: res.lng }));
        });
        let tempArray = [...cityArray]
        let addressArray = [...searchAddress]
        // tempArray.push(latLng.tempAddress.city? latLng.tempAddress.city:latLng.tempAddress.state)
        // if(latLng.tempAddress.sublocality){
        //   tempArray.push(latLng.tempAddress.sublocality)
        //   addressArray.push(latLng.tempAddress.sublocality)
        // }
        if(latLng.tempAddress.locality){
          addressArray.push(latLng.tempAddress?.locality)
          tempArray.push(latLng.tempAddress.locality)
        }
        else if(latLng.tempAddress.city){
          tempArray.push(latLng.tempAddress.city)
          addressArray.push(latLng.tempAddress.city)
        }
        else{
          addressArray.push(latLng.tempAddress.state)
          tempArray.push(latLng.tempAddress.state)
        }
        setCityArray(tempArray)
        setSearchAddress(addressArray)

        setTempState("")

        // updateState((prev) => ({
        //   ...prev,
        //   address: "",
        //   cityArray: tempArray,
        //   locality: "",
        //   street:"",
        // }));
      })


      .catch((error) => console.error("Error", error));
    }else{
      toast.error(`Please select another city in ${selectCity}`)
    }
  };

  useEffect(() => {
    fetchAddress(iState.lat, iState.long);

    setTempState(address);
  }, [address]);

  const fetchAddress = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key='AIzaSyA8KBPjCEIBIU0ujqQ7bacaQ5-dK2bUi7E'`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let tempFormattedAddress = data?.results?.[0]?.place_id ?? "";

        // updateState((prev) => ({...prev,  pinCode: tempFormattedAddress }))
      });
  };

  function extractAddressComponents(data) {
    let city = null;
    let locality = null;
    let sublocality = null;
    let state = null;
    let country = null;

    data.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
      } else if (component.types.includes("sublocality")) {
        if (component.types.includes("sublocality_level_1")) {
          locality = component.long_name;
        }
        if (component.types.includes("sublocality_level_2")) {
          sublocality = component.long_name;
        }
      }
    });

    return { city, locality, sublocality, state, country };
  }

  return (
    <PlacesAutocomplete
      value={tempState}
      onChange={handleChange}
      onSelect={handleSelect}
      // searchOptions={{
      //   types: ["(cities)"],
      //   componentRestrictions: { country: "in" },
      // }}
      // searchOptions={{ types: ['cities', 'country'] }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder:searchAddress?.length>0?"Add more...":"Select location or locality upto 3 keywords",
              className: "location-search-input form-control",
              disabled: searchAddress?.length >= 3 ?true:false,
              style: {
                paddingLeft:
                  searchAddress?.length === 1 ? "140px" :
                  searchAddress?.length === 2 ? "235px" :
                  "30px"
              }
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.filter((elem)=>elem.description.includes(selectCity)).map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <p>{suggestion?.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AutoPlace;
