import React, { useEffect } from 'react'
import img from "../assets/images/about.png";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutusContent } from '../reduxToolKit/slices/staticContentSlice.js/aboutusApiSlice';

const Aboutus = () => {

const {aboutusData} = useSelector((state)=> state.aboutusReducer)
    const dispatch= useDispatch();

    useEffect(()=>{

        dispatch(fetchAboutusContent())

    },[])
  return (
<>
<Header />

<section>
        <div class="about-area">
            <figure>
                <img src="images/about-banner.png" alt="" />
            </figure>
            {/* <figcaption>About Us</figcaption> */}

            <div class="container">
                <h4>About Us</h4>
               <div class="about-us">
                <div class="row ">
                    <div class="col-sm-6">
                    <p
            dangerouslySetInnerHTML={{
              __html: aboutusData?.data?.[0]?.description,
            }}
            className="editor"
          ></p>  
          
                            </div>

                    <div class="col-sm-6">
                        <figure className='mt-4 mt-sm-0'><img src={img} alt="" style={{width:"75%"}}/></figure>
                    </div>
                   </div>
               </div>

               {/* <article>
                    <span>Why Choose Us</span>
                    <fieldset>
                        <h5>Finding your dream stay is our priority</h5>
                        <p>With a collection of destinations that include stunning natural landscapes, vibrant cosmopolitan cities and enchanting tropical islands, we take you to the world's most stunning places.</p>
                    </fieldset>
               </article> */}

               <div class="about-us">
                <div class="row align-items-center">
                    <div class="col-sm-5">
                        <figure><img src="images/about-us.png" alt="" /></figure>
                    </div>
                    {/* <div class="col-sm-7">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    
                    </div> */}

                    
                   </div>
               </div>
            </div>
        </div>
    </section>




<Footer />

</>  )
}

export default Aboutus