import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import {url} from "../../../config/config"



export const fetchOtp = createAsyncThunk('user/fetchUserData', async (payload) => {
  const response = await axios.post(
    `${url}user/validateOtp`,
    payload
  );
  return response;
});
// Define the user slice
export const otpDataSlice = createSlice({
  name: 'user',
  initialState: { data: null, loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default otpDataSlice.reducer;