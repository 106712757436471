import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link, useNavigate, } from "react-router-dom";


import { useDispatch, useSelector } from "react-redux";
import otpApiSlice, { fetchOtp } from "../../reduxToolKit/slices/authSlice.js/otpApiSlice";
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {
  const countryCode = +91
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch()

  const navigate = useNavigate();


  const handleChange = (e) => {
    if (!/^[0-9]{0,10}$/.test(e.target.value) || e.target.value == "e") return;
    {
      setPhone(e.target.value);
    }

    setTimeout(() => {
      if (!e.target.value) {
        setError("* Phone number can't be empty");
      } else if (e.target.value.length < 10) {
        setError("* Enter valid phone number");
      } else {
        setError("");
      }
    }, "1000");
  };

  const handleSubmit = (e) => {

    e.preventDefault();

    if (!error) {
      dispatch(fetchOtp({ phone: phone }))
        .then((res) => {
          console.log(res.payload.data, "LOGIN")

          if (res?.payload?.data?.data?.isExist) {





            navigate('/login-verify', { state: { phone, redirectFrom: "login" } })



          }
          else {

            toast.error("User not found with this phone no. !");





          }



        })

    }
  };





  return (
    <>
      <ToastContainer />

      <Header />
      <section>
        <div class="login-page">
          <aside>
            <form>
              <h2>Login</h2>
              <div class="form-group">
                <label>Phone Number</label>
                {/* <input style={{width:"10px"}} className="form-control" value={countryCode} disabled={true} /> */}
                <input
                  type="text"
                  placeholder="Phone No."
                  value={phone}
                  onChange={handleChange}
                  class="form-control Number"
                />
                <span className="CountryCode">+91</span>

                {error && <p style={{ color: "red" }}>{error}</p>}{" "}
              </div>
              <div class="peragraph">OR Login using</div>
              <a onClick={handleSubmit} class="login-btn">
                Login
              </a>
              <p>
                New to PG DEKHO?{" "}
                <Link to="/create-account">Create account</Link>
              </p>
            </form>
          </aside>
          <article>
            <h2>Features you can access in your PG DEKHO Account</h2>
            <ul>
              <li><strong>Advanced Search Filters: </strong> Use filters to find PGs based on location, budget, amenities, and more.</li>
              <li><strong>AdvancedFavorites: </strong> Save your preferred PG options to a favorites list for easy comparison and access.</li>
              <li><strong>Reviews and Ratings:</strong> Access detailed reviews and ratings from other tenants to make informed decisions.</li>
            </ul>

          </article>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Login;
