import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { s3Keys } from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap-button-loader";

import { ColorRing } from "react-loader-spinner";

import S3FileUpload from "react-s3";
import { useDispatch, useSelector } from "react-redux";
import { fetchPgDetails } from "../reduxToolKit/slices/pgSlices/pgDetailsSlice";
import { createPgApi } from "../reduxToolKit/slices/pgSlices/addPgAddSlice";

const initialState = {
  name: "",

  city: "",
  locality: "",
  street: "",
  address: "",
  location: {
    type: "",
    coordinates: [],
  },
};

const EditProperty1 = () => {
  const [iState, updateState] = useState(initialState);
  const [isLoading, setLoading] = useState(false);

  const { pgDetails } = useSelector((state) => state.pgData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pgRooms, setPgRooms] = useState([
    {
      roomType: "Single Bed",
      monthlyRent: "",
      expectedDeposit: "",
      roomImage: [],
    },
  ]);

  const [singlePgImages, setSinglePgImages] = useState([]);
  const [doublePgImages, setDoublePgImages] = useState([]);
  const [triplePgImages, setTriplePgImages] = useState([]);
  const [fourPgImages, setFourPgImages] = useState([]);

  const [SingleRoomObj, setSingleRoomObj] = useState({});
  const [DoubleRoomObj, setDoubleRoomObj] = useState({
 
  });
  const [TripleRoomObj, setTripeRoomObj] = useState({

  });
  const [FoursRoomObj, setFourRoomObj] = useState({

  });

  // const handleAddDoubleBed = () => {
  //   let obj = pgRooms.filter((o) => o.roomType === "Double Bed");

  //   if (obj.length == 0) {
  //     let tempArray = pgRooms;

  //     tempArray.push({
  //       roomType: "Double Bed",
  //       monthlyRent: "",
  //       expectedDeposit: "",
  //       roomImage: [],
  //     });
  //   }
  // };

  // const handleAddTripleBed = () => {
  //   let obj = pgRooms.filter((o) => o.roomType === "Triple Bed");

  //   if (obj.length == 0) {
  //     let tempArray = pgRooms;

  //     tempArray.push({
  //       roomType: "Triple Bed",
  //       monthlyRent: "",
  //       expectedDeposit: "",
  //       roomImage: [],
  //     });
  //   }
  // };

  // const handleAddOthers = () => {
  //   let obj = pgRooms.filter((o) => o.roomType === "Four Bed");

  //   if (obj.length == 0) {
  //     let tempArray = pgRooms;

  //     tempArray.push({
  //       roomType: "Four Bed",
  //       monthlyRent: "",
  //       expectedDeposit: "",
  //       roomImage: [],
  //     });
  //   }
  // };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e, setter, getter) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ");
    }

    if (file) {
      setLoading(true);

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png"
        ) {
          let res = S3FileUpload.uploadFile(file, s3Keys)
            .then((data) => {
              setLoading(false);

              let temp = [...getter];

              temp.push(data.location);

              setter(temp);
            })
            .catch((err) => console.log(err));
        } else {
          toast.error("Only jpeg, jpg and png are allowed ");
        }
      };
    }
  };

  const handleRemoveSingle = (getter, setter, i) => {
    let tempArray = [...getter];
    tempArray.splice(i, 1);
    setter(tempArray);
  };

  const checkEmptAllField = (obj) => {
    if (!obj?.monthlyRent && !obj?.expectedDeposit) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    if (isValid) {
      setLoading(true);

      let temp = [];

      if (Object?.keys(SingleRoomObj)?.length !== 0) {
        temp.push({
          roomType: "Single Bed",
          monthlyRent: SingleRoomObj.monthlyRent,
          expectedDeposit: SingleRoomObj.expectedDeposit,
          roomImage: singlePgImages,
        });
      }

      if (Object?.keys(DoubleRoomObj)?.length !== 0) {
        temp.push({
          roomType: "Double Bed",
          monthlyRent: DoubleRoomObj.monthlyRent,
          expectedDeposit: DoubleRoomObj.expectedDeposit,
          roomImage: doublePgImages,
        });
      }

      if (Object?.keys(TripleRoomObj)?.length !== 0) {
        temp.push({
          roomType: "Triple Bed",
          monthlyRent: TripleRoomObj.monthlyRent,
          expectedDeposit: TripleRoomObj.expectedDeposit,
          roomImage: triplePgImages,
        });
      }

      if (Object?.keys(FoursRoomObj)?.length !== 0) {
        temp.push({
          roomType: "Four Bed",
          monthlyRent: FoursRoomObj.monthlyRent,
          expectedDeposit: FoursRoomObj.expectedDeposit,
          roomImage: fourPgImages,
        });
      }

      let data = {
        pgRooms: temp,
      };

      dispatch(createPgApi(data))
        .then((res) => {
          if (res.payload.success) {
            navigate("/edit-property-2");

            setLoading(false);

            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    dispatch(fetchPgDetails());
  }, []);

  useEffect(() => {
    let singlePg = pgDetails?.data?.[0]?.pgRooms?.filter(
      (item) => item?.roomType == "Single Bed"
    );
    let doublePg = pgDetails?.data?.[0]?.pgRooms?.filter(
      (item) => item?.roomType == "Double Bed"
    );
    let triplePg = pgDetails?.data?.[0]?.pgRooms?.filter(
      (item) => item?.roomType == "Triple Bed"
    );
    let fourPg = pgDetails?.data?.[0]?.pgRooms?.filter(
      (item) => item?.roomType == "Four Bed"
    );

    setSingleRoomObj(
      singlePg?.length>0
        ? singlePg?.[0]
        : {}
    );
    setDoubleRoomObj(
      doublePg?.length>0
        ? doublePg?.[0]
        : {
           
          }
    );
    setTripeRoomObj(
      triplePg?.length>0
        ? triplePg?.[0]
        : {
           
          }
    );
    setFourRoomObj(
      fourPg?.length>0
        ? fourPg?.[0]
        : {
           
          }
    );

    setSinglePgImages(singlePg?.[0]?.roomImage ? singlePg?.[0]?.roomImage : []);
    setDoublePgImages(doublePg?.[0]?.roomImage ? doublePg?.[0]?.roomImage : []);
    setTriplePgImages(triplePg?.[0]?.roomImage ? triplePg?.[0]?.roomImage : []);
    setFourPgImages(fourPg?.[0]?.roomImage ? fourPg?.[0]?.roomImage : []);
  }, [pgDetails]);

  const handleAddRoom = (e, type) => {
    const { checked } = e.target;

    if (checked && type == "SingleBed") {
      setSingleRoomObj(
       {
              roomType: "Single Bed",
              monthlyRent: "",
              expectedDeposit: "",
              roomImage: [],
            }
      );
    }
    if (!checked && type == "SingleBed") {
      setSingleRoomObj(
       {
            
            }
      );
    }

    if (checked && type == "DoubleBed") {
      setDoubleRoomObj(
       {
              roomType: "Double Bed",
              monthlyRent: "",
              expectedDeposit: "",
              roomImage: [],
            }
      );
    }
    if (!checked && type == "DoubleBed") {
      setDoubleRoomObj(
       {
       }
      );
    }


    if (checked && type == "TripleBed") {
      setTripeRoomObj(
       {
              roomType: "Triple Bed",
              monthlyRent: "",
              expectedDeposit: "",
              roomImage: [],
            }
      );
    }
    if (!checked && type == "TripleBed") {
      setTripeRoomObj(
       {
       }
      );
    }


    if (checked && type == "Others") {
      setFourRoomObj(
       {
              roomType: "Others",
              monthlyRent: "",
              expectedDeposit: "",
              roomImage: [],
            }
      );
    }
    if (!checked && type == "Others") {
      setFourRoomObj(
       {
       }
      );
    }
  };

  return (
    <>
      <ToastContainer />

      <Header />

      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="active">
                Step 1 <span>Room Details</span>{" "}
              </li>
              <li>
                Step 2 <span>Location Details</span>{" "}
              </li>
              <li>
                Step 3 <span>PG Details</span>{" "}
              </li>
              <li>
                Step 4 <span>Amenities Details</span>{" "}
              </li>
              <li>
                Step 5 <span>Gallery</span>{" "}
              </li>
              <li>
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="EditPropertyBox Mergenta">
            <div class="AmmentitiesBox">
              <ul>
                <li>
                  <div class="room-selection">
                    <input type="checkbox" name="SingleBed" onChange={(e)=>handleAddRoom(e,"SingleBed")} checked={Object?.keys(SingleRoomObj)?.length !== 0 } />
                    <aside>
                      <span class="Icon">
                        <img src={require("../assets/images/double-bed.png")} />
                      </span>
                      <span class="NewCheck"></span>
                      <label>Single Bed</label>
                    </aside>
                  </div>
                </li>
                <li>
                  <div class="room-selection">
                    <input type="checkbox" name="DoubleBed"  onChange={(e)=>handleAddRoom(e,"DoubleBed")} checked={Object?.keys(DoubleRoomObj)?.length !== 0 }/>
                    <aside>
                      <span class="Icon">
                        <img src={require("../assets/images/double-bed.png")} />
                      </span>
                      <span class="NewCheck"></span>
                      <label>Double Bed</label>
                    </aside>
                  </div>
                </li>
                <li>
                  <div class="room-selection">
                    <input type="checkbox" name="TripleBed" onChange={(e)=>handleAddRoom(e,"TripleBed")} checked={Object?.keys(TripleRoomObj)?.length !== 0 } />
                    <aside>
                      <span class="Icon">
                        <img src={require("../assets/images/triple-bed.png")} />
                      </span>
                      <span class="NewCheck"></span>
                      <label>Triple Bed</label>
                    </aside>
                  </div>
                </li>
                <li>
                  <div class="room-selection">
                    <input type="checkbox" name="Others"  onChange={(e)=>handleAddRoom(e,"Others")} checked={Object?.keys(FoursRoomObj)?.length !== 0 } />
                    <aside>
                      <span class="Icon">
                        <img src={require("../assets/images/four-bed.png")} />
                      </span>
                      <span class="NewCheck"></span>
                      <label>Others</label>
                    </aside>
                  </div>
                </li>
              </ul>
            </div>

            <div class="ProvideTitleBox">
              <h3>Provide Details of Rent</h3>
            </div>
            <div class="ProvideDetailsBox">
              {/* <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    href="#SingleRoomDetails"
                    class="nav-link active"
                    data-toggle="tab"
                  >
                    <span>
                      <img src={require("../assets/images/icon-1.png")} />
                    </span>
                    Single Room Details
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="#DoubleRoomDetails"
                    class="nav-link"
                    data-toggle="tab"
                  >
                    <span>
                      <img src={require("../assets/images/icon-1.png")} />
                    </span>
                    Double Room Details
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="#TripleRoomDetails"
                    class="nav-link"
                    data-toggle="tab"
                  >
                    <span>
                      <img src={require("../assets/images/icon-1.png")} />
                    </span>
                    Triple Room Details
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#FourRoomDetails" class="nav-link" data-toggle="tab">
                    <span>
                      <img src={require("../assets/images/icon-1.png")} />
                    </span>
                    Four Room Details
                  </a>
                </li>
              </ul> */}

              <div class="tab-content">
                <div class="tab-pane fade active show" id="SingleRoomDetails">
                  <div class="EditPropertyForm">
                    {Object?.keys(SingleRoomObj)?.length !== 0 &&<form>
                    <h3>Single Bed</h3>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <h6>
                              Monthly Rent <span class="required">*</span>
                            </h6>
                            <input
                              type="text"
                              value={SingleRoomObj?.monthlyRent}
                              class="form-control"
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;

                                {
                                  setSingleRoomObj((prev) => ({
                                    ...prev,
                                    monthlyRent: e.target.value,
                                  }));
                                }
                              }}
                            />
                          </div>
                          {/* <div class="form-group">
                            <h6>
                              Upload Photo <span class="required">*</span>
                            </h6>
                            <div class="UploadProfilePhoto">
                              <span>Upload Photo</span>
                              <input
                                type="file"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) =>
                                  handleUpload(
                                    e,
                                    setSinglePgImages,
                                    singlePgImages
                                  )
                                }
                              />
                            </div>
                          </div> */}
                        </div>
                        <div class="col-md-4 offset-2">
                          <div class="form-group">
                            <h6>
                              Expected Deposit <span class="required">*</span>
                            </h6>
                            <input
                              type="text"
                              value={SingleRoomObj?.expectedDeposit}
                              class="form-control"
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;

                                {
                                  setSingleRoomObj((prev) => ({
                                    ...prev,
                                    expectedDeposit: e.target.value,
                                  }));
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-md-5">
                          <div class="PhotoTypeArea">
                            <ul>
                              {singlePgImages?.map((img, i) => (
                                <li>
                                  <figure>
                                    <img src={img} />
                                    <span class="Overlay">
                                      <i
                                        class="fa-solid fa-xmark"
                                        onClick={(e) =>
                                          handleRemoveSingle(
                                            singlePgImages,
                                            setSinglePgImages,
                                            i
                                          )
                                        }
                                      ></i>
                                    </span>
                                  </figure>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>}

                    {Object?.keys(DoubleRoomObj)?.length !== 0 && <form>
                      <h3>Double Bed</h3>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <h6>
                              Monthly Rent <span class="required">*</span>
                            </h6>
                            <input
                              type="text"
                              value={DoubleRoomObj?.monthlyRent}
                              class="form-control"
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setDoubleRoomObj((prev) => ({
                                    ...prev,
                                    monthlyRent: e.target.value,
                                  }));
                                }
                              }}
                            />
                          </div>
                          {/* <div class="form-group">
                            <h6>
                              Upload Photo <span class="required">*</span>
                            </h6>
                            <div class="UploadProfilePhoto">
                              <span>Upload Photo</span>
                              <input
                                type="file"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) =>
                                  handleUpload(
                                    e,
                                    setDoublePgImages,
                                    doublePgImages
                                  )
                                }
                              />
                            </div>
                          </div> */}
                        </div>
                        <div class="col-md-4 offset-2">
                          <div class="form-group">
                            <h6>
                              Expected Deposit <span class="required">*</span>
                            </h6>
                            <input
                              value={DoubleRoomObj?.expectedDeposit}
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setDoubleRoomObj((prev) => ({
                                    ...prev,
                                    expectedDeposit: e.target.value,
                                  }));
                                }
                              }}
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <div class="PhotoTypeArea">
                            <ul>
                              {doublePgImages?.map((img, i) => (
                                <li>
                                  <figure>
                                    <img src={img} />
                                    <span class="Overlay">
                                      <i
                                        class="fa-solid fa-xmark"
                                        onClick={(e) =>
                                          handleRemoveSingle(
                                            doublePgImages,
                                            setDoublePgImages,
                                            i
                                          )
                                        }
                                      ></i>
                                    </span>
                                  </figure>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>}


{ Object?.keys(TripleRoomObj)?.length !== 0 &&  <form>
  <h3>Triple Bed</h3>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <h6>
                              Monthly Rent <span class="required">*</span>
                            </h6>
                            <input
                              type="text"
                              value={TripleRoomObj?.monthlyRent}
                              class="form-control"
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setTripeRoomObj((prev) => ({
                                    ...prev,
                                    monthlyRent: e.target.value,
                                  }));
                                }
                              }}
                            />
                          </div>
                          {/* <div class="form-group">
                            <h6>
                              Upload Photo <span class="required">*</span>
                            </h6>
                            <div class="UploadProfilePhoto">
                              <span>Upload Photo</span>
                              <input
                                type="file"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) =>
                                  handleUpload(
                                    e,
                                    setTriplePgImages,
                                    triplePgImages
                                  )
                                }
                              />
                            </div>
                          </div> */}
                        </div>
                        <div class="col-md-4 offset-2">
                          <div class="form-group">
                            <h6>
                              Expected Deposit <span class="required">*</span>
                            </h6>
                            <input
                              value={TripleRoomObj?.expectedDeposit}
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setTripeRoomObj((prev) => ({
                                    ...prev,
                                    expectedDeposit: e.target.value,
                                  }));
                                }
                              }}
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <div class="PhotoTypeArea">
                            <ul>
                              {triplePgImages?.map((img, i) => (
                                <li>
                                  <figure>
                                    <img src={img} />
                                    <span class="Overlay">
                                      <i
                                        class="fa-solid fa-xmark"
                                        onClick={(e) =>
                                          handleRemoveSingle(
                                            triplePgImages,
                                            setTriplePgImages,
                                            i
                                          )
                                        }
                                      ></i>
                                    </span>
                                  </figure>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>}


                    { Object?.keys(FoursRoomObj)?.length !== 0 &&       <form>

                      <h3>Others</h3>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <h6>
                              Monthly Rent <span class="required">*</span>
                            </h6>
                            <input
                              value={FoursRoomObj?.monthlyRent}
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setFourRoomObj((prev) => ({
                                    ...prev,
                                    monthlyRent: e.target.value,
                                  }));
                                }
                              }}
                              type="text"
                              class="form-control"
                            />
                          </div>
                          {/* <div class="form-group">
                            <h6>
                              Upload Photo <span class="required">*</span>
                            </h6>
                            <div class="UploadProfilePhoto">
                              <span>Upload Photo</span>
                              <input
                                type="file"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) =>
                                  handleUpload(e, setFourPgImages, fourPgImages)
                                }
                              />
                            </div>
                          </div> */}
                        </div>
                        <div class="col-md-4 offset-2">
                          <div class="form-group">
                            <h6>
                              Expected Deposit <span class="required">*</span>
                            </h6>
                            <input
                              value={FoursRoomObj?.expectedDeposit}
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setFourRoomObj((prev) => ({
                                    ...prev,
                                    expectedDeposit: e.target.value,
                                  }));
                                }
                              }}
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <div class="PhotoTypeArea">
                            <ul>
                              {fourPgImages?.map((img, i) => (
                                <li>
                                  <figure>
                                    <img src={img} />
                                    <span class="Overlay">
                                      <i
                                        class="fa-solid fa-xmark"
                                        onClick={(e) =>
                                          handleRemoveSingle(
                                            fourPgImages,
                                            setFourPgImages,
                                            i
                                          )
                                        }
                                      ></i>
                                    </span>
                                  </figure>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>}


                    
                  </div>
                </div>
                <div class="tab-pane fade" id="DoubleRoomDetails">
                  <div class="EditPropertyForm">
                  
                  </div>
                </div>
                <div class="tab-pane fade" id="TripleRoomDetails">
                  <div class="EditPropertyForm">
                    <form>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <h6>
                              Monthly Rent <span class="required">*</span>
                            </h6>
                            <input
                              type="text"
                              value={TripleRoomObj?.monthlyRent}
                              class="form-control"
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setTripeRoomObj((prev) => ({
                                    ...prev,
                                    monthlyRent: e.target.value,
                                  }));
                                }
                              }}
                            />
                          </div>
                          <div class="form-group">
                            <h6>
                              Upload Photo <span class="required">*</span>
                            </h6>
                            <div class="UploadProfilePhoto">
                              <span>Upload Photo</span>
                              <input
                                type="file"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) =>
                                  handleUpload(
                                    e,
                                    setTriplePgImages,
                                    triplePgImages
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 offset-2">
                          <div class="form-group">
                            <h6>
                              Expected Deposit <span class="required">*</span>
                            </h6>
                            <input
                              value={TripleRoomObj?.expectedDeposit}
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setTripeRoomObj((prev) => ({
                                    ...prev,
                                    expectedDeposit: e.target.value,
                                  }));
                                }
                              }}
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <div class="PhotoTypeArea">
                            <ul>
                              {triplePgImages?.map((img, i) => (
                                <li>
                                  <figure>
                                    <img src={img} />
                                    <span class="Overlay">
                                      <i
                                        class="fa-solid fa-xmark"
                                        onClick={(e) =>
                                          handleRemoveSingle(
                                            triplePgImages,
                                            setTriplePgImages,
                                            i
                                          )
                                        }
                                      ></i>
                                    </span>
                                  </figure>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="tab-pane fade" id="FourRoomDetails">
                  <div class="EditPropertyForm">
                    <form>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <h6>
                              Monthly Rent <span class="required">*</span>
                            </h6>
                            <input
                              value={FoursRoomObj?.monthlyRent}
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setFourRoomObj((prev) => ({
                                    ...prev,
                                    monthlyRent: e.target.value,
                                  }));
                                }
                              }}
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group">
                            <h6>
                              Upload Photo <span class="required">*</span>
                            </h6>
                            <div class="UploadProfilePhoto">
                              <span>Upload Photo</span>
                              <input
                                type="file"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) =>
                                  handleUpload(e, setFourPgImages, fourPgImages)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 offset-2">
                          <div class="form-group">
                            <h6>
                              Expected Deposit <span class="required">*</span>
                            </h6>
                            <input
                              value={FoursRoomObj?.expectedDeposit}
                              onChange={(e) => {
                                if (
                                  !/^[0-9]{0,10}$/.test(e.target.value) ||
                                  e.target.value == "e"
                                )
                                  return;
                                {
                                  setFourRoomObj((prev) => ({
                                    ...prev,
                                    expectedDeposit: e.target.value,
                                  }));
                                }
                              }}
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <div class="PhotoTypeArea">
                            <ul>
                              {fourPgImages?.map((img, i) => (
                                <li>
                                  <figure>
                                    <img src={img} />
                                    <span class="Overlay">
                                      <i
                                        class="fa-solid fa-xmark"
                                        onClick={(e) =>
                                          handleRemoveSingle(
                                            fourPgImages,
                                            setFourPgImages,
                                            i
                                          )
                                        }
                                      ></i>
                                    </span>
                                  </figure>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Buttons">
            <Link to="/sell" class="PreviousBtn">
              Previous
            </Link>
            <Button
              loading={isLoading}
              onClick={handleSubmit}
              class="SaveContinueBtn"
            >
              Save &amp; Continue
            </Button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditProperty1;
