import React , {useEffect, useState} from 'react'
import Header from '../Header'
import Footer from '../Footer'
import OtpInput from './OtpInput'
import  { loginApi } from '../../reduxToolKit/slices/authSlice.js/loginApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import  { fetchOtp } from '../../reduxToolKit/slices/authSlice.js/otpApiSlice';
import { useLocation, useNavigate } from 'react-router-dom'
 
import { ToastContainer, toast } from 'react-toastify';

const LoginVerify = () => {

    const { data, loading } = useSelector((state) => state.otpData);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const  {state} = useLocation()


    const onOTPSubmit = (combineOtp="") => {
        if(combineOtp.length>0) {
            if(combineOtp == data?.data?.otp) {
                dispatch(loginApi(state)).then((res)=>
                {

                    window.localStorage.setItem("pgUser", JSON.stringify(res.payload.data))
                    if(state?.redirectFrom =="login"){
                        navigate(-2, { replace: true })


                    }else{
                        navigate("/congratulation")


                    }
                

                })
                
            
            } else {
                toast.error('Verify OTP is Invalid!');    
            }
        } else {
            toast.error('Verify OTP field is required');
        }
    }

    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(30);

    useEffect(() => {
        const interval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
      
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(interval);
            } else {
              setSeconds(59);
              setMinutes(minutes - 1);
            }
          }
        }, 1000);
      
        return () => {
          clearInterval(interval);
        };
    }, [seconds]);

    const handleResend = (e) => {
        e.preventDefault()
            const reqData = {phone:state.phone};
            dispatch(fetchOtp(reqData))
            .then((res)=>{
                console.log(res);
                    setMinutes(1)
                    setSeconds(30)
                    toast.success('OTP has been sent successfully');
                // } else {
                //     toast.error('Something went wrong! Please try after sometime.');
                // }
            })
            .catch((err)=> {
                console.log(err)
                toast.error(err.response.data.message);
            })
        
    }






  return (<>
      <ToastContainer />


  <Header />

  <section>
        <div class="login-page">
            <aside>
                <form>
                    <h2>Verify</h2>
                    <p>{data?.data?.otp}</p>
                    <h6>You’ll receive a 4-digit verification code on {state?.phone},&nbsp;if you are registered with us</h6>
                    <div class="form-group"> 
                        <div class="OTPBox">
                           <OtpInput  length={4} onOTPSubmit={onOTPSubmit}/>
                        </div>
                    </div>
                    { (seconds !== 0 && minutes !== 0) &&   <a class="login-btn" onClick={onOTPSubmit}>Verify OTP</a>}
                    {seconds > 0 || minutes > 0 ? (
                                    <p>
                                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                    </p>):""}
                    {/* <p>You will receive OTP within </p> */}
                   { (seconds == 0 && minutes == 0) &&                   <a class="login-btn" onClick={handleResend}>Resend</a>
 }

                </form>
            </aside>
            <article>
                <h2>Features you can access in your PG DEKHO Account</h2>
                <ul>
                    <li>
                        Features you can access in your PG DEKHO Account
                    </li>
                    <li>
                        Features you can access in your PG DEKHO Account
                    </li>
                    <li>
                        Features you can access in your PG DEKHO Account
                    </li>
                    <li>
                        Features you can access in your PG DEKHO Account
                    </li>
                </ul>
            </article>
        </div>
    </section>
  <Footer />

  </>
  )
}

export default LoginVerify