
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"


export const fetchReviewlisting = createAsyncThunk('ReviewListing/fetchReviewlistin', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`${url}user/getReviewByFilter` ,{
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
        params: payload
    });
    return response.data;
});

const initialState = {
    reviewData: [],
    status: 'idle',
    error: null,
}
const reviewListingSlice = createSlice({
    name: 'reviewListingData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReviewlisting.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReviewlisting.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reviewData = action.payload;
            })
            .addCase(fetchReviewlisting.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default reviewListingSlice.reducer;
