
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';
import {url} from "../../../config/config"


export const fetchFaqContent = createAsyncThunk('faqContent/fetchFaq', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`${url}user/getFaqListByAppType`, {
        headers: {
            "Authorization": isLoggedIn("swtadmin")
        },
        params:payload
    });
    return response.data;
});

const initialState = {
    faqData: {},
    status: 'idle',
    error: null,
}
const faqSlice = createSlice({
    name: 'faqData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFaqContent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFaqContent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.faqData = action.payload;
            })
            .addCase(fetchFaqContent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default faqSlice.reducer;
