import React, { useEffect ,useState} from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMyProperty } from '../../reduxToolKit/slices/mySlice/myPropertySlice'
import { min } from 'moment/moment'
import { Link } from 'react-router-dom'

const PropertyListing = () => {

    const dispatch = useDispatch()

    const {propertyData}= useSelector((state)=>state.myPropertiyReducer)

    const [type, setType] = useState('ALL')
    const [search, setSearch] = useState('')

    const [readMore, setReadMore] = useState(false);


    useEffect(()=>{

        dispatch(fetchMyProperty({filter:type}))

    },[])

    const handlegetFlat=()=>{
        dispatch(fetchMyProperty({filter: "FLAT"}))
        setType("FLAT")
    }
    const handlegetPG=()=>{
        dispatch(fetchMyProperty({filter: "PG"}))
        setType("PG")
    }

    const handlegetAll=()=>{
        dispatch(fetchMyProperty({filter: "ALL"}))
        setType("ALL")
    }


    const handleSearh=(e)=>{
        dispatch(fetchMyProperty({type, search:e.target.value}))
        setSearch(e.target.value)
    }

  return (
    <>

    <Header />

    <section>
    <div class="my-proprties">
        <div class="right-search-area">
            <div class="activity-search">
             <div class="activity-search-tab">
                 <div class="activity-search-left">
                     <h3>My Properties</h3>
                     <div class="CommonTabs">
                         <ul class="nav nav-tabs">
                             <li class="nav-item">
                                 <a class="nav-link active" data-toggle="tab" href="#all" onClick={handlegetAll}>All</a>
                             </li>
                             <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#pg" onClick={handlegetPG}>PG</a>
                             </li>
                             <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#flat" onClick={handlegetFlat}>Flat</a>
                             </li>
                         </ul>                
                     </div>
                 </div>
                 <div class="activity-search-right">
                     <div class="form-group">
                         <input type="search" class="form-control" placeholder="Search Address, City, ZIP code" />
                     </div>

                     <div class="Actions">Only active &nbsp;
                         <label class="switch">
                             <input type="checkbox" />
                             <span class="slider"></span>
                         </label>
                     </div>
                 </div>
             </div>
             <div class="tab-content">
                 <div class="tab-pane fade active show" id="all">
                   

                     {propertyData?.data?.length>0?propertyData?.data?.map((item)=>{

                       let minAmount = item?.expectedRent ? 0 : Math?.min(
                        ...item?.pgRooms?.map((item) => item?.monthlyRent)
                      );

                      return(

                        <div class="search-body">
                        <div class="search-verified">
                            <span class={item?.status =="Active"?"green-verfied":"red-verfied"}>{item?.status}</span>
                            <div class="left-search-verified">
                                <img src={item?.propertyMedia?.[0]?.mediaUrl} alt="" />
                            </div>

                            <div class="right-properties-listing">
                                <aside>
                                    <h3>{item?.name}</h3>
                                    <p>Property ID <span>{item?.flat_id ||item?.pg_id}</span></p>
                                    <figure>
                                        <span>
                                            <img src="images/edit.png" alt="" />
                                        </span>
                                       <span data-toggle="modal" data-target="#Delete1Modal"><img src="images/delete.png" alt="" /></span>
                                    </figure>
                                </aside>
                                <article>
                                    <div>
                                        <p><img src="images/black-location.png" alt="" />{item?.address}</p>
                                        <h5>₹ {item?.expectedRent?item?.expectedRent: minAmount}</h5>
                                    </div>
                                    <Link  state={item} class="button" to="/property-responses">Contacted (0)</Link>
                                </article>
                            </div>    
                            
                        </div>

                        <div class="float-properties-option">
                            <ul class="flatOption">
                                <li>
                                    <p>Purchase Plan Date</p>
                                    <h6>16/04/2024</h6>
                                </li>
                                <li>
                                    <p>Plan Expire Date</p>
                                    <h6>16/04/2024</h6>
                                </li>
                                <li>
                                    <p>Auto Renewal</p>
                                    <h6>Yes</h6>
                                </li>
                                <li>
                                    <p>Plan Type</p>
                                    <h6>Monthly</h6>
                                </li>
                                <li>
                                    <p>Plan Name</p>
                                    <h6>Silver</h6>
                                </li>
                            </ul> 
                            <ul>
                                <li><a class="button" >Upgrade</a>  </li>
                            </ul>                             
                        </div>
                    </div>
                      )
                 }
                      
                    ):<div><img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e' style={{display:"block", margin:"auto",width:"45%"}}/></div>}
                 </div>

                 <div class="tab-pane fade" id="pg">
                 {propertyData?.data?.length>0?propertyData?.data?.map((item)=>{
                   
                   let minAmount = item?.expectedRent ? 0 : Math?.min(
                    ...item?.pgRooms?.map((item) => item?.monthlyRent)
                  );


                      return(

                        <div class="search-body">
                        <div class="search-verified">
                            <span class={item?.status =="Active"?"green-verfied":"red-verfied"}>{item?.status}</span>
                            <div class="left-search-verified">
                                <img src={item?.propertyMedia?.[0]?.mediaUrl} alt="" />
                            </div>

                            <div class="right-properties-listing">
                                <aside>
                                <h3>{item?.name}</h3>

                                    <p>Property ID <span>{item?.flat_id ||item?.pg_id}</span></p>
                                    <figure>
                                        <span>
                                            <img src="images/edit.png" alt="" />
                                        </span>
                                       <span data-toggle="modal" data-target="#Delete1Modal"><img src="images/delete.png" alt="" /></span>
                                    </figure>
                                </aside>
                                <article>
                                    <div>
                                        <p><img src="images/black-location.png" alt="" />{item?.address}</p>
                                        <h5>₹ {minAmount}</h5>
                                    </div>
                                    <button class="button">Contacted (0)</button>
                                </article>
                            </div>    
                            
                        </div>

                        <div class="float-properties-option">
                            <ul class="flatOption">
                                <li>
                                    <p>Purchase Plan Date</p>
                                    <h6>16/04/2024</h6>
                                </li>
                                <li>
                                    <p>Plan Expire Date</p>
                                    <h6>16/04/2024</h6>
                                </li>
                                <li>
                                    <p>Auto Renewal</p>
                                    <h6>Yes</h6>
                                </li>
                                <li>
                                    <p>Plan Type</p>
                                    <h6>Monthly</h6>
                                </li>
                                <li>
                                    <p>Plan Name</p>
                                    <h6>Silver</h6>
                                </li>
                            </ul> 
                            <ul>
                                <li><a class="button" >Upgrade</a>  </li>
                            </ul>                             
                        </div>
                    </div>
                      )
                 }
                      
                    ):<div><img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e' style={{display:"block", margin:"auto",width:"45%"}}/></div>}
               
                 </div>
 
                 <div class="tab-pane fade" id="flat">
                    {propertyData?.data?.length>0?propertyData?.data.map((item)=>
                          <div class="search-body">
                          <div class="search-verified">
                              <span class={item?.status =="Active"?"green-verfied":"red-verfied"}>{item?.status}</span>
                              <div class="left-search-verified">
                                  <img src={item?.propertyMedia?.[0]?.mediaUrl} alt="" />
                              </div>
 
                              <div class="right-properties-listing">
                                  <aside>
                                  <h3>{item?.apartmentName}</h3>

                                      <p>Property ID <span>{item?.flat_id ||item?.pg_id}</span></p>
                                      <figure>
                                          <span>
                                              <img src="images/edit.png" alt="" />
                                          </span>
                                         <span data-toggle="modal" data-target="#Delete1Modal"><img src="images/delete.png" alt="" /></span>
                                      </figure>
                                  </aside>
                                  <h3> {item?.BHKType} Flats for Rent</h3>
                                  <article>
                                      <div>
                                          <p><img src="images/black-location.png" alt="" />{item?.address}</p>
                                          <h5>₹ {item?.expectedRent}</h5>
                                      </div>
                                      <button class="button">Contacted (0)</button>
                                  </article>
                              </div>    
                              
                          </div>
 
                          <div class="float-properties-option">
                              <ul class="flatOption">
                                  <li>
                                      <p>Purchase Plan Date</p>
                                      <h6>16/04/2024</h6>
                                  </li>
                                  <li>
                                      <p>Plan Expire Date</p>
                                      <h6>16/04/2024</h6>
                                  </li>
                                  <li>
                                      <p>Auto Renewal</p>
                                      <h6>Yes</h6>
                                  </li>
                                  <li>
                                      <p>Plan Type</p>
                                      <h6>Monthly</h6>
                                  </li>
                                  <li>
                                      <p>Plan Name</p>
                                      <h6>Silver</h6>
                                  </li>
                              </ul> 
                              <ul>
                                  <li><a class="button" >Upgrade</a>  </li>
                              </ul>                             
                          </div>
                      </div>
                    ):<div><img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e' style={{display:"block", margin:"auto",width:"45%"}}/></div>}
               
                 </div>
             </div>
            </div>                        
         </div>
    </div>
   </section>

   <Footer />
    
    </>
  )
}

export default PropertyListing