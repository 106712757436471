import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createFlatApi } from "../reduxToolKit/slices/flatSlice.js/addFlatSlice";
import { fetchFlatDetails } from "../reduxToolKit/slices/flatSlice.js/flatDetailsSilce";
import moment from "moment/moment";

const FlatProperty3 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { flatDetails } = useSelector((state) => state.flatData);


  const initialState = {
    availableFrom: "",
    maintenanceAmount: "",
    expectedRent: "",
    expectedDeposit: "",
    rentNegotiable: false,
    parking: true,
    vehicleType: "",
    furnishing: "",
    preferredTenant:"",
    description:"",

    errorMsg: {},
  };

  const [PREFERRED_DATA, setPreferedData] = useState([
    {
      name: "Anyone",
      isSelect: false,
      value:"Anyone"
  },
  {
      name: "Family",
      isSelect: false,
      value:"Family"
  },
  {
      name: "Bachelor Female",
      isSelect: false,
      value:"Bachelor Female"
  },
  {
      name: "Bachelor Male",
      isSelect: false,
      value:"Bachelor Male"
  },
  {
      name: "Company",
      isSelect: false,
      value:"Company"
  }
  ]);

  const [iState, updateState] = useState(initialState);

  const {
    availableFrom,
    maintenanceAmount,
    expectedRent,
    expectedDeposit,
    rentNegotiable,
    parking,
    furnishing,
    vehicleType,
    preferredTenant,
    description,
    errorMsg,
  } = iState;


  const handleRadio = (e, setter, prop) => {
    updateState({ ...iState, [setter]: prop });
  };



  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (!availableFrom) {
      flag = false;
      errorMsg.availableFrom = "Available from  field is required";
    }

    if (!maintenanceAmount) {
      flag = false;
      errorMsg.maintenanceAmount = "Maintenance amount  field is required";
    }

    if (!expectedDeposit) {
      flag = false;
      errorMsg.expectedDeposit = "Expected deposit field is required";
    }

    if (!expectedRent) {
      flag = false;
      errorMsg.expectedRent = "Expected rent field is required";
    }

    if (!preferredTenant) {
      flag = false;
      errorMsg.preferredTenant = "Preferred tenant field is required";
    }

    if (!vehicleType && iState.parking) {
      flag = false;
      errorMsg.vehicleType = "Vehicle type field is required";
    }

    if (!furnishing) {
      flag = false;
      errorMsg.furnishing = "Furnishing field is required";
    }
    if (!description) {
      flag = false;
      errorMsg.description = "Description field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  const handleSubmitFlat = (e) => {
    e.preventDefault();

    let isValid = handleValidation();
    console.log(isValid , "isvalid")
    if (isValid) {
      let data = {
        availableFrom,
        maintenanceAmount,
        expectedRent,
        expectedDeposit,
        rentNegotiable,
        parking,
        furnishing,
        vehicleType,
        preferredTenant,
        description,
      };
      dispatch(createFlatApi(data))
        .then((res) => {
          if (res.payload.success) {
         
              navigate("/flat-property4");

            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {});
    }
  };


  useEffect(() => {
    dispatch(fetchFlatDetails());
  }, []);


  useEffect(() => {
    updateState({
      ...iState,

      availableFrom: flatDetails?.data?.[0]?.availableFrom?flatDetails?.data?.[0]?.availableFrom:"",
      maintenanceAmount: flatDetails?.data?.[0]?.maintenanceAmount ?flatDetails?.data?.[0]?.maintenanceAmount:"",
      expectedRent: flatDetails?.data?.[0]?.expectedRent?flatDetails?.data?.[0]?.expectedRent:"",
      expectedDeposit: flatDetails?.data?.[0]?.expectedDeposit ? flatDetails?.data?.[0]?.expectedDeposit:"",
      vehicleType: flatDetails?.data?.[0]?.vehicleType?flatDetails?.data?.[0]?.vehicleType:"",
      furnishing: flatDetails?.data?.[0]?.furnishing ? flatDetails?.data?.[0]?.furnishing:"",
      preferredTenant:flatDetails?.data?.[0]?.preferredTenant ?flatDetails?.data?.[0]?.preferredTenant:"",

      rentNegotiable:flatDetails?.data?.[0]?.rentNegotiable?flatDetails?.data?.[0]?.rentNegotiable: false,
      parking: flatDetails?.data?.[0]?.parking?flatDetails?.data?.[0]?.parking:false,


     

    });
  }, [flatDetails]);


  return (
    <>

<ToastContainer />

      <Header />

      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="Previous">
                Step 1 <span>Property Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>
              </li>
              <li class="Previous">
                Step 2 <span>Location Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>{" "}
                </h6>{" "}
              </li>
              <li class="active">
                Step 3 <span>Rental Details</span>{" "}
              </li>
              <li>
                Step 4 <span>Amenities</span>{" "}
              </li>
              <li>
                Step 5 <span>Gallery</span>{" "}
              </li>
              <li>
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="EditPropertyBox">
            <h3 class="Title">Provide Details of Flat</h3>
            <div class="EditPropertyForm">
              <form>
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-9">
                    <div class="form-group">
                      <h6>
                        Available From <span class="required">*</span>
                      </h6>
                      <input
                        type="date"
                        class="form-control"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        max="9999-12-31"  
                        value={moment(availableFrom)?.format("YYYY-MM-DD")}
                        onChange={handleChange}
                        name="availableFrom"
                        placeholder="Enter Available From"
                      />

                      {errorMsg.availableFrom && !availableFrom && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.availableFrom}
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-4 offset-lg-2 col-md-6 col-sm-9">
                    <div class="form-group">
                      <h6>
                        Maintenance Amount <span class="required">*</span>
                      </h6>
                      <input
                        type="text"
                        placeholder="Enter Maintenace Amount"
                        class="form-control"
                        onChange={(e) => {
                          if (
                            !/^[0-9]{0,10}$/.test(e.target.value) ||
                            e.target.value == "e"
                          )
                            return;

                          {
                            updateState((prev) => ({
                              ...prev,
                              maintenanceAmount: e.target.value,
                            }));
                          }
                        }}
                        value={maintenanceAmount}
                      />

                      {errorMsg.maintenanceAmount && !maintenanceAmount && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.maintenanceAmount}
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-9">
                    <div class="form-group">
                      <h6>
                        Rent Negotiable <span class="required">*</span>
                      </h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              type="radio"
                              checked={rentNegotiable}
                              name="rentNegotiable"
                              onChange={(e) =>
                                handleRadio(e, "rentNegotiable", true)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              type="radio"
                              checked={!rentNegotiable}
                              name="rentNegotiable"
                              onChange={(e) =>
                                handleRadio(e, "rentNegotiable", false)
                              }
                            />{" "}
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-lg-4 offset-lg-2 col-md-6 col-sm-9">
                    <div class="form-group">
                      <h6>
                        Security Deposit <span class="required">*</span>
                      </h6>
                      <input
                        type="text"
                        placeholder="Enter Security Deposit"
                        class="form-control"
                        value={expectedDeposit}
                        onChange={(e) => {
                          if (
                            !/^[0-9]{0,10}$/.test(e.target.value) ||
                            e.target.value == "e"
                          )
                            return;

                          {
                            updateState((prev) => ({
                              ...prev,
                              expectedDeposit: e.target.value,
                            }));
                          }
                        }}
                      />

                      {errorMsg.expectedDeposit && !expectedDeposit && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.expectedDeposit}
                        </p>
                      )}
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 col-sm-9">
                    <div class="form-group">
                      <h6>
                        Expected Rent <span class="required">*</span>
                      </h6>
                      <input
                        type="text"
                        placeholder="Enter Expected Rent"
                        class="form-control"
                        value={expectedRent}
                        onChange={(e) => {
                          if (
                            !/^[0-9]{0,10}$/.test(e.target.value) ||
                            e.target.value == "e"
                          )
                            return;

                          {
                            updateState((prev) => ({
                              ...prev,
                              expectedRent: e.target.value,
                            }));
                          }
                        }}
                      />

                      {errorMsg.expectedRent && !expectedRent && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.expectedRent}
                        </p>
                      )}
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <h6>Preferred Tenant</h6>
                      <ul class="Extra">
                        {PREFERRED_DATA?.map((rule, i) => (
                          <li>
                            <label class="CheckBox">
                              {" "}
                              {rule?.name}
                              <input
                              checked={preferredTenant ==rule?.value? true : false}
                              type="radio"
                              name="preferredTenant"
                              onChange={(e) => {
                                updateState((prev) => ({
                                  ...prev,
                                  preferredTenant: rule?.value,
                                }));
                              }}
                            />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>

                      {errorMsg.preferredTenant &&
                        preferredTenant?.length == 0 && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.preferredTenant}
                          </p>
                        )}
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <h6>
                        Parking <span class="required">*</span>
                      </h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              type="radio"
                              checked={parking}
                              name="parking"
                              onChange={(e) => handleRadio(e, "parking", true)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              type="radio"
                              checked={!parking}
                              name="parking"
                              onChange={(e) => handleRadio(e, "parking", false)}
                            />{" "}
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <h6>
                        Furnishing <span class="required">*</span>
                      </h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Fully-Furnished
                            <input
                              type="radio"
                              checked={furnishing?.includes("Fully-Furnished")}
                              name="Fully-Furnished"
                              onChange={(e) =>
                                handleRadio(e, "furnishing", "Fully-Furnished")
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>

                        

                        <li>
                          <label class="Radio">
                            {" "}
                            Semi-Furnished
                            <input
                              type="radio"
                              checked={furnishing?.includes("Semi-Furnished")}
                              name="Semi-Furnished"
                              onChange={(e) =>
                                handleRadio(e, "furnishing", "Semi-Furnished")
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            Unfurnished
                            <input
                              type="radio"
                              checked={furnishing?.includes("Unfurnished")}
                              name="Unfurnished"
                              onChange={(e) =>
                                handleRadio(e, "furnishing", "Unfurnished")
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>

                      {errorMsg.furnishing && !furnishing && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.furnishing}
                        </p>
                      )}
                    </div>
                  </div>

                  {parking &&

                  <div class="col-lg-12">
                    <div class="form-group">
                      <h6>Vehicle Type</h6>
                      <ul>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Car
                            <input
                              checked={vehicleType == "Car" ? true : false}
                              type="radio"
                              name="vehicleType"
                              onChange={(e) => {
                                updateState((prev) => ({
                                  ...prev,
                                  vehicleType: "Car",
                                }));
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Bike
                            <input
                              checked={vehicleType == "Bike" ? true : false}
                              type="radio"
                              name="vehicleType"
                              onChange={(e) => {
                                updateState((prev) => ({
                                  ...prev,
                                  vehicleType: "Bike",
                                }));
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Both
                            <input
                              checked={vehicleType == "Both" ? true : false}
                              type="radio"
                              name="vehicleType"
                              onChange={(e) => {
                                updateState((prev) => ({
                                  ...prev,
                                  vehicleType: "Both",
                                }));
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>

                      {errorMsg.vehicleType && !vehicleType && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.vehicleType}
                        </p>
                      )}
                    </div>
                  </div>}
                </div>
                <div class="col-lg-12">
                    <div class="form-group mb-0">
                      <h6>Description</h6>
                      <textarea
                        onChange={handleChange}
                        value={description}
                        name="description"
                        class="form-control"
                        id="editor"
                      ></textarea>
                       {errorMsg.description && !description && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.description}
                        </p>
                      )}
                    </div>
                  </div>
              </form>
            </div>
            
          </div>
          <div class="Buttons">
            <Link to="/flat-property2" class="PreviousBtn">
              Previous
            </Link>
            <a onClick={handleSubmitFlat} class="SaveContinueBtn">
              Save &amp; Continue
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FlatProperty3;
