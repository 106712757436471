
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"


export const fetchTicket = createAsyncThunk('Support/fetchTicket', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`${url}user/getTickets`,{
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
        params: payload
    });
    return response.data;
});

const initialState = {
    ticketData: [],
    status: 'idle',
    error: null,
}
const myTicketSlice = createSlice({
    name: 'myTicketData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTicket.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTicket.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.ticketData = action.payload;
            })
            .addCase(fetchTicket.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default myTicketSlice.reducer;
