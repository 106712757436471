
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';
import {url} from "../../../config/config"


export const fetchTermsContent = createAsyncThunk('TermsContent/fetchTerms', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`${url}user/websiteTermAndConditionContent`, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;
});

const initialState = {
    termsData: {},
    status: 'idle',
    error: null,
}
const termsSlice = createSlice({
    name: 'termsData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTermsContent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTermsContent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.termsData = action.payload;
            })
            .addCase(fetchTermsContent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default termsSlice.reducer;
