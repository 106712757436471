import React, { useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchPropertyResponselist } from '../../reduxToolKit/slices/mySlice/propertyResponseSlice'

const PropertyResponses = () => {


    const dispatch = useDispatch();

    const {state} = useLocation()


    useEffect(()=>{

        dispatch(fetchPropertyResponselist({propertyId: state?._id}))



    },[])
  return (
<>

<Header />

<section>
    <div class="my-proprties">
        <div class="right-search-area">
            <div class="activity-search">
             <div class="activity-search-tab">
                 <div class="activity-search-left">
                     <h3>My Properties</h3>                        
                 </div>
             </div>
             <div class="search-body">
                 <div class="search-verified">
                     <div class="left-search-verified">
                         <img src="images/properties.png" alt="" />
                     </div>

                     <div class="right-properties-listing">
                         <aside>
                             <p>Property ID <span>#43857945</span></p>
                         </aside>
                         <h3> 2 BHK Flats for Rent</h3>
                         <article>
                             <div>
                                 <p><img src={require("../../assets/images/black-location.png")} alt="" />Indirapuram, Ghaziabad</p>
                             </div>
                         </article>
                     </div>    
                     
                 </div>
             </div>

             <div class="search-body">
                 <div class="response">
                     <aside>
                         <h3>James Smith</h3>
                         <h4>+9178832479523</h4>
                         <h5>Sector 55,Gurgaon</h5>
                     </aside>
                     <p>Contacted on 15 Dec 2023</p>
                 </div>
             </div>
             <div class="search-body">
                 <div class="response">
                     <aside>
                         <h3>James Smith</h3>
                         <h4>+9178832479523</h4>
                         <h5>Sector 55,Gurgaon</h5>
                     </aside>
                     <p>Contacted on 15 Dec 2023</p>
                 </div>
             </div>
             <div class="search-body">
                 <div class="response">
                     <aside>
                         <h3>James Smith</h3>
                         <h4>+9178832479523</h4>
                         <h5>Sector 55,Gurgaon</h5>
                     </aside>
                     <p>Contacted on 15 Dec 2023</p>
                 </div>
             </div>

            </div>                        
         </div>
    </div>
   </section>

   <Footer />

</>  )
}

export default PropertyResponses