
import {  createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"



export const addContactOwnerApi = createAsyncThunk('Contact/addContact', async (payload) => {

    try{

    const response = await axios.post(`${url}user/contactOwner`,payload, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;

}
catch(err){

    return err;


}
});