import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchFlatResultDetails } from "../reduxToolKit/slices/flatSlice.js/flatResultDetails";
import StarRatings from "react-star-ratings";
import { dateTimeFormat } from "./helpers/dateTimeFormat";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import MapView from "./MapView";
import moment from "moment";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

import { Line } from "rc-progress";
import { createRatingApi } from "../reduxToolKit/slices/pgSlices/addRatingSlice";
import { fetchReviewlisting } from "../reduxToolKit/slices/pgSlices/reviewListingSlice";
import { isLoggedIn } from "../utils";

import { PiSwimmingPoolBold } from "react-icons/pi";
import { GiEscalator } from "react-icons/gi";
import { FaHouseUser } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { MdBedroomChild } from "react-icons/md";
import { FaCartShopping } from "react-icons/fa6";
import { PiPipeLight } from "react-icons/pi";
import { FaParking } from "react-icons/fa";
import { GiFruitTree } from "react-icons/gi";

const amenitiesArray = [
  { name: "Lift", img: <GiEscalator style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
  { name: "Intercom", img: <BsTelephoneFill style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
  { name: "Swimming Pool", img: <PiSwimmingPoolBold style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
  { name: "Club House", img: <FaHouseUser style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
  { name: "Servent Room", img: <MdBedroomChild style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
  { name: "Gas Pipeline", img: <PiPipeLight style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
  { name: "Park", img: <GiFruitTree style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
  { name: "Shopping Center", img: <FaCartShopping style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
  { name: "Free Parking", img: <FaParking style={{color:"#FD701E",fontSize:"22px", marginRight:"10px"}}/> },
];

const DropDownInterface = [
  {
    label: "Currently staying in the Pg",
    value: "Currently staying in the Pg",
  },
  {
    label: "Not staying in this Pg",
    value: "Not staying in this Pg",
  },
  {
    label: "Have stayed in this PG 6 months ago",
    value: "Have stayed in this PG 6 months ago",
  },
  {
    label: "Have stayed in this PG a year ago",
    value: "Have stayed in this PG a year ago",
  },
  {
    label: "Never stay in this PG",
    value: "Never stay in this PG",
  },
];

const initialState = {
  type: "FLAT",
  locality: "",
  pgName: "",
  propId: "",
  currentStatus: "",
  rating: 0,
  feedbacks: "",
  scrollStateName: "",
  errorMsg: {},
};

const FlatDetails = () => {
  const [mapData, setMapData] = useState({ place: "university" });

  const { reviewData } = useSelector((state) => state.reviewReducer);

  const [reviewType, setReviewType] = useState("Top-Rated");

  const [ContacModal, setContact] = useState(false);
  const handleContactModalClose = () => setContact(false);
  const handleContactModalShow = (id) => {
    setContact(true);
  };

  const [activeTab, SetActiveTavb] = useState("Details");

  const { flatResult } = useSelector((state) => state.flatResult);
  const nearPlace = [
    {
      label: "Education",
      value: "university",
    },
    {
      label: "Restaurants",
      value: "restaurant",
    },
    {
      label: "Metro Station",
      value: "subway_station",
    },
    {
      label: "Shopping Center",
      value: "shopping_mall",
    },
    {
      label: "Hospital",
      value: "hospital",
    },
  ];

  const dispatch = useDispatch();

  const [ImageModal, SetImageModal] = useState({
    photoIndex: 0,
    isOpen: false,
  });

  const { photoIndex, isOpen } = ImageModal;

  const [iState, updateState] = useState(initialState);

  const {
    currentStatus,
    feedbacks,
    rating,
    locality,
    pgName,
    type,
    errorMsg,
    scrollStateName,
  } = iState;

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => {
    updateState({ ...iState, rating: 0, currentStatus: "", feedbacks: "" });
    setDelete(false);
  };
  const handleDeleteModalShow = (id) => {
    setDelete(true);
  };

  const { state } = useLocation();

  useEffect(() => {
    dispatch(fetchFlatResultDetails({ id: state?._id }));

    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    updateState({
      ...iState,
      pgName: flatResult?.data?.[0]?.apartmentName,
      locality: flatResult?.data?.[0]?.city,
    });
  }, [flatResult]);

  const initialRatingCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  const ratingCounts =
    flatResult?.data?.[0]?.ratings?.length > 0
      ? flatResult?.data?.[0]?.ratings.reduce((acc, curr) => {
          acc[curr.rating] = (acc[curr.rating] || 0) + 1;
          return acc;
        }, initialRatingCounts)
      : null;

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (!currentStatus) {
      flag = false;
      errorMsg.currentStatus = "Current status field is required";
    }

    if (rating == 0) {
      flag = false;
      errorMsg.rating = " Rating  is required";
    }

    if (!feedbacks) {
      flag = false;
      errorMsg.feedbacks = "Feedback  is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = handleValidation();

    if (isValid) {
      let data = {
        type: "FLAT",
        locality: locality,
        pgName: pgName,
        propId: flatResult?.data?.[0]?._id,
        currentStatus: currentStatus,
        rating: rating,
        feedbacks: feedbacks,
      };

      dispatch(createRatingApi(data))
        .then((res) => {
          if (res.payload.success) {
            toast.success("Review added successfully");
            setDelete(false);

            dispatch(
              fetchReviewlisting({
                type: "FLAT",
                filter: reviewType,
                propertyId: state?._id,
              })
            );
            dispatch(fetchFlatResultDetails({ id: state?._id }));

            updateState({
              ...iState,
              rating: 0,
              currentStatus: "",
              feedbacks: "",
            });

            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {});
    }
  };

  const scrollHandle = (id) => {
    SetActiveTavb(id);
    const element = document.getElementById(id);
    updateState({ ...iState, scrollStateName: id });
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const bannerImage = flatResult?.data?.[0]?.propertyMedia?.filter(
    (item) => item.default === true
  );

  useEffect(() => {
    dispatch(
      fetchReviewlisting({
        type: "FLAT",
        filter: reviewType,
        propertyId: state?._id,
      })
    );
  }, [reviewType, state]);

  return (
    <>
      <ToastContainer />

      <Header />

      <section>
        <div class="PropertyBox">
          <div class="PropertyHead">
            <figure>
              <img
                src={
                  bannerImage?.length > 0
                    ? bannerImage[0]?.mediaUrl
                    : require("../assets/images/property-1.png")
                }
              />
              {flatResult?.data?.[0]?.verified && (
                <div class="VerifyOverlay">
                  <span>
                    <img src={require("../assets/images/verified.png")} />
                  </span>
                  Verified
                </div>
              )}
            </figure>
            <figcaption>
              <aside>
                <h3>{flatResult?.data?.[0]?.apartmentName} </h3>
                <StarRatings
                  rating={flatResult?.data?.[0]?.rating}
                  starRatedColor="#f89117"
                  starDimension="20px"
                  starSpacing="0px"
                />
                <span>
                  {" "}
                  {flatResult?.data?.[0]?.rating}(
                  {flatResult?.data?.[0]?.ratings?.length} Reviews)
                </span>
              </aside>
              <h4>
                <i class="fa-solid fa-location-dot"></i>{" "}
                {flatResult?.data?.[0]?.address}
              </h4>
              <h6>
                ₹ {flatResult?.data?.[0]?.expectedRent}{" "}
                <span>See other charges</span>{" "}
                <img src={require("../assets/images/impot.png")} alt="" />
              </h6>
              <p>
                <label>Occupancy Type:</label>{" "}
                <span>{flatResult?.data?.[0]?.BHKType}</span>
              </p>

              <ul>
                <li>{flatResult?.data?.[0]?.BHKType}</li>
                <li>{flatResult?.data?.[0]?.apartmentType}</li>
                {flatResult?.data?.[0]?.gym && <li>Gym</li>}
                {flatResult?.data?.[0]?.parking && <li>Parking</li>}
              </ul>

              <ul>
                <li> {flatResult?.data?.[0]?.preferredTenant}</li>
                <li> {flatResult?.data?.[0]?.builtUpArea}</li>
                <li> {flatResult?.data?.[0]?.preferredTenant}</li>
                <li>
                  {" "}
                  {flatResult?.data?.[0]?.floor} out of{" "}
                  {flatResult?.data?.[0]?.totalFloor}
                </li>
              </ul>
            </figcaption>
          </div>
        </div>
      </section>

      <section>
        <div class="OrderDetail">
          <ul class="Direction">
            <li>
              <a
                onClick={() => scrollHandle("Details")}
                className={activeTab == "Details" ? "active" : ""}
              >
                Property Details
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">Occupancy Options</a>
            </li>
            {/* <li>
              <a
                onClick={() => scrollHandle("Charges")}
                className={activeTab == "Charges" ? "active" : ""}
              >
                Charges
              </a>
            </li> */}
            <li>
              <a
                onClick={() => scrollHandle("Amenities")}
                className={activeTab == "Amenities" ? "active" : ""}
              >
                Amenities
              </a>
            </li>
            {/* <li>
              <a
                onClick={() => scrollHandle("Rules")}
                className={activeTab == "Rules" ? "active" : ""}
              >
                House Rules
              </a>
            </li> */}
            <li>
              <a
                onClick={() => scrollHandle("Location")}
                className={activeTab == "Location" ? "active" : ""}
              >
                Location
              </a>
            </li>
            <li>
              <a
                onClick={() => scrollHandle("Review")}
                className={activeTab == "Review" ? "active" : ""}
              >
                Reviews
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div class="HotelDetailsArea">
          <div class="hotel-area">
            <div class="HotelDetailsLeft">
              <figure onClick={(()=> SetImageModal({...ImageModal, isOpen:true })) }>
                <a data-toggle="modal" data-target="#ViewImageModal">
                  <img
                    src={
                      flatResult?.data?.[0]?.propertyMedia
                      ?.filter((ele) => ele?.mediaType == "Image")?.length >0 ? flatResult?.data?.[0]?.propertyMedia
                      ?.filter((ele) => ele?.mediaType == "Image")?.[0]?.mediaUrl
                        : require("../assets/images/property-1.png")
                    }
                  />
                </a>
              </figure>
              <ul>
                {flatResult?.data?.[0]?.propertyMedia?.slice(0,3)
                  ?.filter((ele) => ele?.mediaType == "Image")
                  ?.map((image,i) => (
                    <li>
                                            <figure onClick={(()=> SetImageModal({...ImageModal, isOpen:true, photoIndex:i })) }>

                      <a data-toggle="modal" data-target="#ViewImageModal">
                        <img src={image?.mediaUrl} style={{height:"100px"}}/>
                      </a>

                      {i==2 && <figcaption   onClick={(()=> SetImageModal({...ImageModal, isOpen:true })) }>+ {flatResult?.data?.[0]?.propertyMedia?.filter((ele)=> ele?.mediaType=="Image")?.length} MORE</figcaption>}

</figure>
                    </li>
                  ))}
              </ul>
            </div>
            <div class="HotelDetailsRight">

            <article>
              <aside>
                <h2 style={{fontSize:"22px", fontWeight:"700",textAlign:"left"}}>{flatResult?.data?.[0]?.apartmentName}</h2>

                </aside>
              </article>
        
              <article>
               
              <aside>



            <h3>{flatResult?.data?.[0]?.address}</h3>
        
                        </aside>
                        <aside>
          
                  <a onClick={() => scrollHandle("Location")}>
                    <span>
                      <img src={require("../assets/images/location.png")} />
                    </span>
                    View on map
                  </a>
                </aside>
              </article>
              <div className="TableListNew">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        Preferred Tenant
                        <span>{flatResult?.data?.[0]?.preferredTenant}</span>
                      </p>
                    </td>
                    <td>
                      <p>
                        Carpet Area{" "}
                        <span> {flatResult?.data?.[0]?.builtUpArea}</span>
                      </p>
                    </td>
                    <td>
                      <p>
                        Deposit{" "}
                        <span>
                          Rs.
                          {flatResult?.data?.[0]?.expectedDeposit}
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        Available From
                        <span>
                          {moment(flatResult?.data?.[0]?.availableFrom).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </p>
                    </td>
                    <td>
                      <p>
                        Parking{" "}
                        <span>{flatResult?.data?.[0]?.vehicleType}</span>
                      </p>
                    </td>
                    <td>
                      <p>Facing - {flatResult?.data?.[0]?.facing}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        Floor
                        <span>
                          {flatResult?.data?.[0]?.floor} out of{" "}
                          {flatResult?.data?.[0]?.totalFloor}
                        </span>
                      </p>
                    </td>
                    <td>
                      <p>
                        Rent Negotiable <span>No</span>
                      </p>
                    </td>
                    <td>
                      <p>
                        Age of Construction
                        <span>{flatResult?.data?.[0]?.propertyAge} years</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p></p>
                    </td>
                    <td>
                      <p></p>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div class="BrandButton text-right">
                <a
                  class="button"
                  onClick={() => {
                    if (isLoggedIn("pgUser")) {
                      setContact(true);
                    }
                  }}
                >
                  Contact Owner
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="writereview">
          <div class="OrderDetails WriteReview" id="Reviews">
            <h5>
              Show some love &amp; Let us know how good <br /> this Flat is
            </h5>
            <div class="TitleBox">
              <a
                class="TitleLink Button"
                onClick={() => {
                  if (isLoggedIn("pgUser")) {
                    setDelete(true);
                  }
                }}
              >
                Write a review &nbsp;
                <i class="fa-solid fa-arrow-right text-white"></i>{" "}
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div class="OrderDetails" id="Charges">
          <div class="row">
            {flatResult?.data?.[0]?.pgRooms?.map((item) => (
              <div class="col-md-3">
                <div class="ViewContactBox">
                  <figure>
                    <img
                      src={
                        item?.roomImage?.length > 0
                          ? item?.roomImage?.[0]
                          : require("../assets/images/property-1.png")
                      }
                    />
                  </figure>
                  <figcaption>
                    <h3>{item?.roomType}</h3>
                    <p>
                      <label>Rent</label>
                      <span>Rs. {item?.monthlyRent}</span>
                    </p>
                    <p>
                      <label>Deposit</label>
                      <span>Rs. {item?.expectedDeposit}</span>
                    </p>
                    <div class="RoomAmenities">
                      <h4>Room Amenities</h4>
                      <ul>
                        <li>
                          <span>
                            <img
                              src={require("../assets/images/facility-1.png")}
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img
                              src={require("../assets/images/facility-2.png")}
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img
                              src={require("../assets/images/facility-3.png")}
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img
                              src={require("../assets/images/facility-4.png")}
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img
                              src={require("../assets/images/facility-5.png")}
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img
                              src={require("../assets/images/facility-6.png")}
                            />
                          </span>
                        </li>
                        <li>
                          <small>+More</small>
                        </li>
                      </ul>
                    </div>
                  </figcaption>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section>
        <div class="writereview">
          <div class="OrderDetails">
            <article class="flex-column">
              <aside class="w-100">
                <h5>Description</h5>
                <p>{flatResult?.data?.[0]?.description}</p>
              </aside>
            </article>
          </div>
        </div>
      </section>

      <section>
        <div class="orderDetails-new">
          <div class="OrderDetails" id="Amenities">
            <h5>Amenities</h5>
            <article>
              <aside class="w-100">
                <div class="Amenities">
                  <ul>
                  {flatResult?.data?.[0]?.availableAmenities?.map((ele) => (
                          <li>
                            <span>
                             {   amenitiesArray?.filter(
                                    (roomAmenity) => roomAmenity?.name == ele
                                  )?.[0]?.img}{ele}
                            </span>
                          </li>
                        ))}
                  </ul>
                </div>
              </aside>
            </article>
          </div>

          {/* <div class="OrderDetails" id="Rules">
            <h5>House Rules</h5>
            <article>
              <aside class="w-100">
                <div class="Amenities Amenities2">
                  <ul>
                    {flatResult?.data?.[0]?.pgHostelRule?.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              </aside>
            </article>
          </div> */}
        </div>
      </section>

      <section>
        <div class="OrderDetails">
          <ul class="OrderDetails-3">
            {nearPlace?.map((place) => (
              <li>
                <a
                  onClick={() => setMapData({ ...mapData, place: place.value })}
                  className={place.value == mapData.place ? "active" : ""}
                >
                  {place.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <div id="Location">
        <MapView
          mapData={mapData}
          currentLocation={{
            lat: flatResult?.data?.[0]?.location?.coordinates?.[0],
            lng: flatResult?.data?.[0]?.location?.coordinates?.[1],
          }}
        />
      </div>

{flatResult?.data?.[0]?.ratings?.length>0 &&
      <section class="Rating-body">
        <div class="OrderDetails" id="Review">
          <h5>Rating and Reviews</h5>
          <article>
            <aside class="w-100">
              <div class="d-flex">
                <div class="RatingsTop">
                  <h1>{flatResult?.data?.[0]?.rating?.toFixed(1)}</h1>

                  <StarRatings
                    rating={flatResult?.data?.[0]?.rating}
                    starRatedColor="#f89117"
                    starDimension="20px"
                    starSpacing="0px"
                  />
                  <p>({flatResult?.data?.[0]?.ratings?.length} Reviews)</p>
                </div>
                <div class="ProgressBarArea">
                  <ul>
                    <li>
                      <label>5</label>

                      <Line
                        percent={
                          parseFloat(
                            ratingCounts?.["5"] /
                              flatResult?.data?.[0]?.ratings?.length
                          ) * 100
                        }
                        strokeWidth={4}
                        strokeColor="#f89117"
                      />

                      <strong>{ratingCounts?.["5"]}</strong>
                    </li>
                    <li>
                      <label>4</label>

                      <Line
                        percent={
                          parseFloat(
                            ratingCounts?.["4"] /
                              flatResult?.data?.[0]?.ratings?.length
                          ) * 100
                        }
                        strokeWidth={4}
                        strokeColor="#f89117"
                      />

                      <strong>{ratingCounts?.["4"]}</strong>
                    </li>
                    <li>
                      <label>3</label>

                      <Line
                        percent={
                          parseFloat(
                            ratingCounts?.["3"] /
                              flatResult?.data?.[0]?.ratings?.length
                          ) * 100
                        }
                        strokeWidth={4}
                        strokeColor="#f89117"
                      />

                      <strong>{ratingCounts?.["3"]}</strong>
                    </li>
                    <li>
                      <label>2</label>

                      <Line
                        percent={
                          parseFloat(
                            ratingCounts?.["2"] /
                              flatResult?.data?.[0]?.ratings?.length
                          ) * 100
                        }
                        strokeWidth={4}
                        strokeColor="#f89117"
                      />

                      <strong>{ratingCounts?.["2"]}</strong>
                    </li>

                    <li>
                      <label>1</label>

                      <Line
                        percent={
                          parseFloat(
                            ratingCounts?.["1"] /
                              flatResult?.data?.[0]?.ratings?.length
                          ) * 100
                        }
                        strokeWidth={4}
                        strokeColor="#f89117"
                      />

                      <strong>{ratingCounts?.["1"]}</strong>
                    </li>
                  </ul>
                </div>
              </div>
              {}

              <div class="rating-tab">
                <div class="CommonTabs">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        onClick={() => setReviewType("Top-Rated")}
                        href="#top"
                      >
                        Top-Rated
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#low"
                        onClick={() => setReviewType("Low-Rated")}
                      >
                        Low Rating
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#favorites"
                        onClick={() => setReviewType("Newest")}
                      >
                        Newest
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#gems"
                        onClick={() => setReviewType("Hidden-Gem")}
                      >
                        Hidden Gems
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {reviewData?.data?.map((item) => (
                <div class="RatingsBox">
                  <aside>
                    <div class="UserDetailsBox">
                      <h5>{item?.user?.[0]?.name}</h5>
                      <p> {dateTimeFormat(item?.updatedAt)} </p>
                      <span>
                        <img
                          src={
                            item?.user?.[0]?.profileImage
                              ? item?.user?.[0]?.profileImage
                              : require("../assets/images/profile-1.png")
                          }
                          alt="img"
                        />
                      </span>
                    </div>
                    <StarRatings
                      rating={item?.rating}
                      starRatedColor="#f89117"
                      starDimension="20px"
                      starSpacing="0px"
                    />
                  </aside>
                  <aside>
                    <p>{item?.feedbacks}</p>
                  </aside>
                </div>
              ))}
            </aside>
          </article>
        </div>
      </section>
}
      <Footer />

      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={handleDeleteModalClose}
              class="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <h3>Give Feedback</h3>
            <h4>Enter details of the PG you want to rate</h4>
            <form>
              <div class="form-group">
                <label>Enter Locality</label>
                <input
                  type="text"
                  class="form-control"
                  disabled={true}
                  value={locality}
                  placeholder="Enter Locality."
                />
              </div>
              <div class="form-group">
                <label>Enter Flat Name</label>
                <input
                  type="text"
                  disabled={true}
                  class="form-control"
                  value={pgName}
                  placeholder="Enter Pg name."
                />
              </div>
              <div class="form-group">
                <label>What's your current status?</label>
                <select
                  class="form-control"
                  value={currentStatus}
                  name="currentStatus"
                  onChange={handleChange}
                >
                  <option>--Select--</option>
                  {DropDownInterface?.map((item) => (
                    <option value={item?.value}>{item?.label}</option>
                  ))}
                </select>{" "}
                {errorMsg.currentStatus && !currentStatus && (
                  <p className="error-msg" style={{ color: "red" }}>
                    {errorMsg.currentStatus}
                  </p>
                )}
              </div>
            </form>
            <h4>Submit Rating</h4>

            <StarRatings
              rating={rating}
              changeRating={(e) => updateState({ ...iState, rating: e })}
              starRatedColor="#f89117"
              starDimension="20px"
              starSpacing="0px"
            />

            {errorMsg.rating && !rating && (
              <p className="error-msg" style={{ color: "red" }}>
                {errorMsg.rating}
              </p>
            )}

            <div class="form-group">
              <label>Write Feedback</label>
              <input
                type="text"
                value={feedbacks}
                name="feedbacks"
                onChange={handleChange}
                class="form-control"
                placeholder="Enter Feedback."
              />
              {errorMsg.feedbacks && !feedbacks && (
                <p className="error-msg" style={{ color: "red" }}>
                  {errorMsg.feedbacks}
                </p>
              )}
            </div>

            <a onClick={handleSubmit} class="Button">
              Submit
            </a>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ContacModal}
        onHide={handleContactModalClose}
        className="ModalBox"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={handleContactModalClose}
              class="CloseModal"
              data-dismiss="modal"
              style={{cursor:"pointer"}}
            >
              ×
            </a>
            <h3>Contact Details</h3>
            <figure>
              <img
                src={
                  flatResult?.data?.[0]?.owner?.[0]?.profileImage
                    ? flatResult?.data?.[0]?.owner?.[0]?.profileImage
                    : require("../assets/images/user.jfif")
                }
                alt=""
              />
            </figure>
            <figcaption>
              <p>Name :- {flatResult?.data?.[0]?.owner?.[0]?.name}</p>
              {/* <p>
                Phone Number :- +91 {flatResult?.data?.[0]?.owner?.[0]?.phone}
              </p> */}
              <p>
                Phone Number :- {flatResult?.data?.[0]?.contactNumber && `${flatResult?.data?.[0]?.contactNumber} ,`}
                {flatResult?.data?.[0]?.owner?.[0]?.phone}
              </p>
              <p>
                {flatResult?.data?.[0]?.contactEmail &&
                flatResult?.data?.[0]?.contactEmail !== "" ? (
                  <>Email: {flatResult?.data?.[0]?.contactEmail}</>
                ) : (
                  ""
                )}
              </p>

              {/* <h6>
                Note : It will take some time for the property to be verified by
                our team. You will be notified once your property is listed
              </h6> */}
            </figcaption>
          </div>
        </Modal.Body>
      </Modal>

      {isOpen && (
        <Lightbox
          mainSrc={
            flatResult?.data?.[0]?.propertyMedia?.filter(
              (ele) => ele?.mediaType == "Image"
            )?.[photoIndex]?.mediaUrl
          }
          nextSrc={
            flatResult?.data?.[0]?.propertyMedia?.filter(
              (ele) => ele?.mediaType == "Image"
            )?.[
              (photoIndex + 1) %
                flatResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length
            ]
          }
          prevSrc={
            flatResult?.data?.[0]?.propertyMedia?.filter(
              (ele) => ele?.mediaType == "Image"
            )?.[
              (photoIndex +
                flatResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length -
                1) %
                flatResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length
            ]
          }
          onCloseRequest={() => SetImageModal({ isOpen: false, photoIndex })}
          onMovePrevRequest={() =>
            SetImageModal({
              photoIndex:
                (photoIndex +
                  flatResult?.data?.[0]?.propertyMedia?.filter(
                    (ele) => ele?.mediaType == "Image"
                  )?.length -
                  1) %
                flatResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length,
              isOpen,
            })
          }
          onMoveNextRequest={() =>
            SetImageModal({
              photoIndex:
                (photoIndex + 1) %
                flatResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length,
              isOpen,
            })
          }
        />
      )}
    </>
  );
};

export default FlatDetails;
