import {  createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"


export const updateProfileApi = createAsyncThunk('updateProfile/updateproleApi', async (payload) => {
    const response = await axios.put(`${url}user/updateProfile
`,payload, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;
});