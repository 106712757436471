import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { raiseTicketApi } from "../../reduxToolKit/slices/supportSlice/raiseTicketSlice";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { callRequestApi } from "../../reduxToolKit/slices/supportSlice/callRequestSlice";

const initialState = {
  userType: "",
  issues: "",
  description: "",
  phone: "9821323232",

  errorMsg: {},
};
const HelpAndSupport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [iState, updateState] = useState(initialState);

  const { userType, issues, description, phone, errorMsg } = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (!userType) {
      flag = false;
      errorMsg.userType = " User type field is required";
    }

    if (!phone) {
      flag = false;
      errorMsg.phone = " Phone field is required";
    } else if (phone < 10) {
      flag = false;
      errorMsg.phoneValid = " Phone number is not valid";
    }

    if (!issues) {
      flag = false;
      errorMsg.issues = "Isssue  field is required";
    }

    if (!description) {
      flag = false;
      errorMsg.description = "Description  field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    let data = {
      userType: userType,
      issues: issues,
      description: description,
      phone: phone,
    };

    console.log(data);

    if (formIsValid) {
      dispatch(raiseTicketApi(data)).then((res) => {
        if (res?.payload?.success) {
          toast.success("Raise ticket successfully !");

          

                 navigate("/my-ticket-support");

        } else {
          toast.error("Something went wrong !");

          // navigate("/login-verify", { state: iState });
        }
      });
    }
  };


  const handleCallRequest = (e) => {
    e.preventDefault();

    let formIsValid = true

    let data = {
        userType: "Tenant",
   
      phone: phone,
    };

    console.log(data);

    if (formIsValid) {
      dispatch(callRequestApi(data)).then((res) => {
        if (res?.payload?.success) {
          toast.success("Requested successfully !");
        } else {
          toast.error("Something went wrong !");

          // navigate("/login-verify", { state: iState });
        }
      });
    }
  };

  return (
    <>
      <ToastContainer />

      <Header />
      <div class="help">
        <div class="help-area">
          <aside>
            <h3>Raise an issue</h3>
            <a onClick={handleCallRequest}>Request A call Back</a>
          </aside>

          <article>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>You are a *</label>
                  <select
                    class="form-control"
                    onChange={handleChange}
                    name="userType"
                  >
                    <option value={""}>Select</option>

                    <option value={"Owner"}>Owner</option>
                    <option value={"Agent"}>Agent</option>
                    <option value={"Someone looking for Property"}>
                      Someone looking for Property
                    </option>
                  </select>

                  {errorMsg.userType && !userType && (
                    <p className="error-msg" style={{ color: "red" }}>
                      {errorMsg.userType}
                    </p>
                  )}
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Select issue you are facing from list*</label>
                  <select
                    class="form-control"
                    onChange={handleChange}
                    name="issues"
                  >
                    <option value={""}>Select</option>

                    <option value={"Fake Property"}>Fake Property</option>
                    <option value={"Difficulty in posting property"}>
                      Difficulty in posting property
                    </option>
                  </select>

                  {errorMsg.issues && !issues && (
                    <p className="error-msg" style={{ color: "red" }}>
                      {errorMsg.issues}
                    </p>
                  )}
                </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label></label>
                  <CKEditor
                    id="editor1"
                    className="form-control"
                    editor={ClassicEditor}
                    //data={overview} // add in state and use state key
                    data={description} // add in state and use state key
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      updateState({ ...iState, description: data });
                    }}
                  />
                  {errorMsg.description && !description && (
                    <p className="error-msg" style={{ color: "red" }}>
                      {errorMsg.description}
                    </p>
                  )}{" "}
                </div>
              </div>

              <div class="col-sm-12">
                <a onClick={handleSubmit} class="button">
                  Raise Ticket
                </a>
              </div>
            </div>
          </article>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HelpAndSupport;
