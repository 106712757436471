import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { Link } from 'react-router-dom'

const Congratulation = () => {
  return (
<>
<Header />
<section>
        <div class="login-page">
            <aside>
               <form>
                <div class="congrts">
                    <h2>Congratulations</h2>
                    <figure><img src={require("../../assets/images/cong.png")} alt="" /></figure>
                    <p>WelcomebackFabiana</p>
                    <Link to="/" class="login-btn" >Login Again</Link>
                </div>
               </form>
            </aside>
            <article>
                <h2>Features you can access in your PG DEKHO Account</h2>
                <ul>
                    <li>
                        Features you can access in your PG DEKHO Account
                    </li>
                    <li>
                        Features you can access in your PG DEKHO Account
                    </li>
                    <li>
                        Features you can access in your PG DEKHO Account
                    </li>
                    <li>
                        Features you can access in your PG DEKHO Account
                    </li>
                </ul>
            </article>
        </div>
    </section>
    <Footer /></>
    )
}

export default Congratulation