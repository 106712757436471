import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFaqContent } from '../reduxToolKit/slices/staticContentSlice.js/faqApiSlice'

const Faq = () => {

    const dispatch = useDispatch();

    const {faqData} = useSelector((state)=>state.faqReducer)

    useEffect(()=>{

        dispatch(fetchFaqContent({appType:"Website"}))

    },[])




  return (

    <>

    <Header />


    
    <section>
        <div class="privacy-area">
            <figure>
                <img src="images/privacy-banner.png" alt="" />
            </figure>
            <figcaption>FAQs</figcaption>

            <div class="container">
                <div class="privacy-top">
                    <h3>FAQS</h3>
                  
                </div>

              


{faqData?.data?.map((faq)=>


<div class="privacy-top">
<h4>{faq?.question}</h4>
<p>{faq?.answer}</p>
</div>
)}
            </div>
        </div>
    </section>
    
    <Footer />

    </>
  )
}

export default Faq