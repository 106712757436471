import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import AutoPlace from "./AutoPlace";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { createPgApi } from "../reduxToolKit/slices/pgSlices/addPgAddSlice";
import { createFlatApi } from "../reduxToolKit/slices/flatSlice.js/addFlatSlice";
import Button from "react-bootstrap-button-loader";
import { FaCreativeCommonsZero } from "react-icons/fa";
import { TbDeviceMobileOff } from "react-icons/tb";
import { MdVerifiedUser } from "react-icons/md";

const initialState = {
  name: "",
  lat: "",
  long: "",
 mobile:"",
 email:"",
  city: "",
  locality: "",
  street: "",
  address: "",
  location: {
    type: "point",
    coordinates: [],
  },
  errorMsg: {},
};
const Sell = () => {

  const [isLoading, setLoading] = useState(false);

  const [iState, updateState] = useState(initialState);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [propertyType, setPropertyType] = useState("pg")

  const { address, lat, long, name ,city,street,locality, errorMsg} = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };
  

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (!name) {
      flag = false;
      errorMsg.name = " Name field is required";
    }

    if (!address) {
      flag = false;
      errorMsg.address = "Address field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = handleValidation();
    let data = {}
    if (isValid) {
      setLoading(true)
    if(street){
      data= {  name,
        city,
        locality,
        street,
        address,
        contactNumber:iState.mobile,
        contactEmail:iState.email,
        location: {
          type: "Point",
          coordinates: [lat,long],
        },}
      }
      else{
        data= { 
           name,
           locality,
           contactNumber:iState.mobile,
           contactEmail:iState.email,
          city,
          address,
          location: {
            type: "Point",
            coordinates: [lat, long],
          },}
      }
      dispatch(createPgApi(data))
      .then((res) => {
        if (res.payload.success) {

          setLoading(false)


          setTimeout(() => {
            navigate("/edit-property-1");
          }, 1000);

          toast.success(res.message);

        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {});
    }
  };


  const handleSubmitFlat = (e) => {
    e.preventDefault();

    let isValid = handleValidation();
    if (isValid) {
      let data= {  apartmentName:name,
        locality,
        city,
        address,
        contactNumber:iState.mobile,
        contactEmail:iState.email,
        location: {
          type: "Point",
          coordinates: [lat,long],
        },}

        if(street){
          data= {  name,
            city,
            locality,
            contactNumber:iState.mobile,
            contactEmail:iState.email,
            street,
            address,
            location: {
              type: "Point",
              coordinates: [lat,long],
            },}
          }
    
          else{
            data= {  name,
              city,
              address,
              location: {
                type: "Point",
                coordinates: [lat, long],
              },}
          }
      dispatch(createFlatApi(data))
      .then((res) => {
        if (res.payload.success) {

          setTimeout(() => {
            navigate("/flat-property1");
          }, 1000);

          toast.success(res.message);

        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {});
    }
  };

  return (
    <>
            <ToastContainer />

      <Header />

      <section>
        <div class="SellArea">
          <div class="Sell-conatnt">
            <div class="Sell-body">
              <aside>
                <form>
                  <h2>Rent Your PG or Flats with us</h2>
                  <div class="form-group">
                    <h6>Property Type</h6>
                    <ul>
                      <li>
                        <label class="Radio">
                          <input type="radio" checked={propertyType=="pg"?true :false} name="boy" onChange={()=> setPropertyType("pg")} />
                          <span class="checkmark"></span>PG /Hostel
                        </label>
                      </li>
                      <li>
                        <label class="Radio">
                          <input type="radio" name="boy" onChange={()=> setPropertyType("flay")} />
                          <span class="checkmark"></span>Flat
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label>Select Location *</label>
                    {/* <input type="text" class="form-control" placeholder="Select Loaction" /> */}
                    <AutoPlace
                      updateState={updateState}
                      iState={iState}
                      address={address}
                    />
                    {errorMsg.address && !address && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.address}
                      </p>
                    )}
                  </div>
                  
                  <div class="form-group">
                    <label>PG Name /Flat Name *</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={name}
                      name="name"
                      class="form-control"
                      placeholder="PG Name / Flat Name"
                    />
                    {errorMsg.name && !name && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.name}
                      </p>
                    )}
                  </div>

                  <div class="form-group">
                    <label>Property Contact No. (Optional)</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={iState.mobile}
                      name="mobile"
                      class="form-control"
                      placeholder="Property contact number"
                    />
                    {errorMsg.name && !name && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.name}
                      </p>
                    )}
                  </div>

                  <div class="form-group">
                    <label>Property Email (Optional)</label>
                    <input
                      type="email"
                      onChange={handleChange}
                      value={iState.email}
                      name="email"
                      class="form-control"
                      placeholder="Property Email"
                    />
                    
                  </div>

                  {propertyType=="pg"?
                       <Button
                  loading={isLoading} onClick={handleSubmit}>Post your property</Button>
                  :
                  <Button
                  loading={isLoading}  onClick={handleSubmitFlat}>Post your property</Button>
                  }
                </form>
                <figure>
                  <img src={require("../assets/images/sell.png")} alt="" />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="WhyChooseUsArea">
          <div class="section-title">
            <p>How it works</p>
            <h3>
              Maximize your house hunting with <br /> our innovative features
            </h3>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="WhyChooseBox">
                <span class="Icon">
                  {/* <img src={require("../assets/images/money-send.png")} /> */}
                  <FaCreativeCommonsZero style={{fontSize:"32px",color:"#fff"}}/>
                </span>
                <h5>ZERO PLATFORM FEE</h5>
                <p>
                Enjoy Free Access to Our Platform. Browse Listings and Contact Property Owners Directly Without Any Fee.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="WhyChooseBox">
                <span class="Icon">
                  {/* <img src={require("../assets/images/money-send.png")} /> */}
                  <MdVerifiedUser style={{fontSize:"32px",color:"#fff"}}/>
                </span>
                <h5>SEARCH VERIFIED PROPERTIES</h5>
                <p>
                Discover Your Ideal Place at Your Own Pace. Experience Immersive Photos and Extensive Listings, All Verified for Your Peace of Mind.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="WhyChooseBox">
                <span class="Icon">
                  {/* <img src={require("../assets/images/money-send.png")} /> */}
                  <TbDeviceMobileOff style={{fontSize:"32px",color:"#fff"}}/>
                </span>
                <h5>NO UNWANTED CALLS</h5>
                <p>
                Enjoy a Seamless Search Experience with Complete Control Over Who Contacts You and When.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="more-services">
          <div class="section-title">
            <p>More Services</p>
            <h3>We’ll give you some more services</h3>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <figure>
                <img src={require("../assets/images/service1.png")} alt="" />
              </figure>
            </div>
            <div class="col-sm-6">
              <figcaption>
                <h3>Simple Listing Process</h3>
                <p>
                  As an owner you can list your property in a few minutes. Just
                  fill out our super simple form. Your property will go live
                  after verification.
                </p>
              </figcaption>
            </div>
            <div class="col-sm-6 order-2 order-sm-1">
              <figcaption>
                <h3>
                  Tenant Selects Property and <br /> Schedules an Appointment
                </h3>
                <p>
                  If a tenant likes your property they will request for your
                  contact details. Both parties will receive contact information
                  and then arrange for a visit.
                </p>
              </figcaption>
            </div>
            <div class="col-sm-6 order-1 order-sm-2">
              <figure>
                <img src={require("../assets/images/service1.png")} alt="" />
              </figure>
            </div>
            <div class="col-sm-6">
              <figure>
                <img src={require("../assets/images/service1.png")} alt="" />
              </figure>
            </div>
            <div class="col-sm-6">
              <figcaption>
                <h3>Deal Closure</h3>
                <p>
                  Owner and tenant meet to close the deal directly. NoBroker can
                  help create a rental agreement and deliver it to your
                  doorstep.
                </p>
              </figcaption>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="faq">
          <div class="container">
            <h2>Frequently Asked Question</h2>

            <div class="Small-Wrapper">
              <div class="StaticBoxArea">
                <div class="panel-group" id="accordion">
                  <div class="panel">
                    <div
                      class="panel-heading collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse1"
                      aria-expanded="false"
                    >
                      <h5>When selling my house, where should I begin?</h5>
                    </div>
                    <div id="collapse1" class="panel-collapse collapse">
                      <div class="panel-body">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ipsam nesciunt sit, facere in magni ipsa illum
                          iste culpa quaerat nisi perferendis nemo veritatis
                          doloremque ab quo animi sunt, aut asperiores.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="panel">
                    <div
                      class="panel-heading collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse2"
                      aria-expanded="false"
                    >
                      <h5>How long does it take to sell a house?</h5>
                    </div>
                    <div id="collapse2" class="panel-collapse collapse">
                      <div class="panel-body">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Reprehenderit qui ut sapiente laboriosam
                          explicabo quas ipsa aut illum et, ex quis magni beatae
                          eaque iure at autem necessitatibus libero dolorum?
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="panel">
                    <div
                      class="panel-heading collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse3"
                      aria-expanded="false"
                    >
                      <h5>What home seller mistakes should I avoid?</h5>
                    </div>
                    <div id="collapse3" class="panel-collapse collapse">
                      <div class="panel-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Non dolor voluptatum quasi qui veritatis
                          corrupti rerum dolorum. Explicabo dolorem obcaecati
                          pariatur tempora fugit. Quis, alias. Eveniet fugit
                          similique architecto rem.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="panel">
                    <div
                      class="panel-heading collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse4"
                      aria-expanded="false"
                    >
                      <h5>**Markets with a reduced listing fee</h5>
                    </div>
                    <div id="collapse4" class="panel-collapse collapse">
                      <div class="panel-body">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Sed ad ipsam cumque libero dolore? Totam omnis
                          pariatur vitae quisquam, odit nulla iusto, laborum
                          fuga eius impedit ullam dicta placeat officiis!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="faq-img">
                  <span class="left-faq">
                    <img
                      src={require("../assets/images/left-faq.png")}
                      alt=""
                    />
                  </span>
                  <span class="right-faq">
                    <img
                      src={require("../assets/images/right-faq.png")}
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="property">
          <div class="proprty-area">
            <aside>
              <figure>
                <img src={require("../assets/images/discount.png")} alt="" />
              </figure>
              <h3>Find Property</h3>
              <p>Select from thousands of options, without brokerage.</p>
              <a href="index.html">Find Now</a>
            </aside>

            <aside>
              <figure>
                <img src={require("../assets/images/tim.png")} alt="" />
              </figure>
              <h3>List Your Property</h3>
              <p>For Free. Without any brokerage.</p>
              <a href="#">Free Posting</a>
            </aside>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Sell;
