import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPgDetails } from "../reduxToolKit/slices/pgSlices/pgDetailsSlice";
import { toast } from "react-toastify";
import { createPgApi } from "../reduxToolKit/slices/pgSlices/addPgAddSlice";
import { completePgApi } from "../reduxToolKit/slices/pgSlices/completePgSlice";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { url } from "../config/config";
import { isLoggedIn } from "../utils";
import locImg from "../assets/images/map-loc-img.png"

const initialState = {

  laundryService:{},
  selectTimeSchedule: "",
  startTime: "",
  endTime: "",
  laundryService:"",
  postBy:"",


  errorMsg: {},
};
const EditProperty6 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = JSON.parse(window.localStorage.getItem("pgUser"));

  const [SubscriptionModal, setSubscription] = useState(false);
  const [planList , setPlanList] = useState()
  const handleSubscriptionModalClose = () => setSubscription(false);
  const handleSubscriptionModalShow = (id) => {
    setSubscription(true);
  };

  const [PaymentModal, setPayment] = useState(false);
  const handlePamentModalClose = () => setPayment(false);
  const handlePamentModalShow = (id) => {
    setPayment(true);
  };

  const [iState, updateState] = useState(initialState);
  const { selectTimeSchedule, startTime, endTime, laundryService, errorMsg,postBy } = iState;

  const { pgDetails } = useSelector((state) => state.pgData);


  useEffect(() => {
    dispatch(fetchPgDetails());
    fetchPlanDetails()
  }, []);

  const fetchPlanDetails=async()=>{
    try {
      const response = await axios.get(`http://pgdekhoindia.com:8083/api/v1/admin/getAllPlanOwnerDetails`, {
          headers: {
              "Authorization": isLoggedIn("pgAdmin"),
          },
      });
    if(response?.data?.success){
      setPlanList(response?.data?.data?.result?.[0]);
    }
  } catch (error) {
      console.error('Error fetching plan list:', error);
      throw error; // Rethrow the error to be handled by the calling code
  }
  }

  console.log(planList)

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};


    if(Object.keys(laundryService).length === 0){

              flag = false;
      errorMsg.laundryService = "Laundry Service field is required";



    }

    if (!selectTimeSchedule) {
      flag = false;
      errorMsg.selectTimeSchedule = " Schedule  field is required";
    }

    if (selectTimeSchedule == "Specific time") {
      if (!startTime) {
        flag = false;
        errorMsg.startTime = " Start time  field is required";
      }

      if (!endTime) {
        flag = false;
        errorMsg.endTime = " End time  field is required";
      }

      if (startTime > endTime) {
        flag = false;
        errorMsg.endTimeValid = " End time is must be less than  start time";
      }
    }

    if (!postBy) {
      flag = false;
      errorMsg.postBy = " Post By  field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = handleValidation();
    if (isValid) {
      let data = {
        selectTimeSchedule,
        startTime,
        endTime,
        postBy
      };

      dispatch(createPgApi(data))
        .then((res) => {
          if (res.payload.success) {
       
            setSubscription(true)

            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {});
    }
    handleComplete()
  };


  
  const handleRadio = (e, setter, prop) => {
    updateState({ ...iState, [setter]: prop });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };



  
  

  const handleComplete=()=>{
    dispatch(
      completePgApi({
        pgId: pgDetails?.data?.[0]?._id,
        // postBy: "Owner",
      })
    ).then((res) => {
      if (res.payload.success) {
          navigate("/");
      }
    });
  }

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (elem) => {
    const res = await loadRazorpayScript();

    if (!res) {
      alert('Failed to load Razorpay SDK');
      return;
    }

    const options = {
      key: 'rzp_test_t3BDOjSAeHzUvi', // Replace with your Razorpay key ID
      // amount: elem?.pricingInformation?.AnnualDiscountedRate, // Amount in paise (e.g., 50000 paise = INR 500)
      subscription_id:elem?.rzp_test_t3BDOjSAeHzUvi,
      currency: 'INR',
      name: 'PG Dekho',
      description: 'Test Subscription Plan',
      image: locImg, // Replace with your company logo
      handler: function (response) {
        alert('Payment Successful');
        console.log(response);
      },
      prefill: {
        name: 'Test name',
        email: 'john.doe@example.com',
        contact: '9999999999',
      },
      notes: {
        address: 'text address',
      },
      theme: {
        color: '#FD701E',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };




  return (
    <>
      <Header />

      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="Previous">
                Step 1 <span>Room Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>
              </li>
              <li class="Previous">
                Step 2 <span>Location Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>{" "}
              </li>
              <li class="Previous">
                Step 3 <span>PG Details</span>{" "}
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>{" "}
                </h6>
              </li>
              <li class="Previous">
                Step 4 <span>Amenities Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>
              </li>
              <li class="Previous">
                Step 5 <span>Gallery</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>
              </li>
              <li class="active">
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="EditPropertyBox">
            <h3 class="Title">
              Make house visit easier provide your availability
            </h3>
            <div class="EditPropertyForm">
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="AvailabilityBox">
                                        <ul>
                                            <li>
                                                <input type="radio" name="everyday" 
                                                onChange={(e) =>
                                                    handleRadio(e, "laundryService", {title:"Everyday",days:"Mon-Sun"})
                                                  } />
                                                <aside>
                                                    <label>Everyday</label>
                                                    <h6>Mon-Sun</h6>
                                                </aside>
                                            </li>
                                            <li>
                                                <input type="radio" name="everyday"   onChange={(e) =>
                                                    handleRadio(e, "laundryService", {title:"Weekdays",days:"Mon-Fri"})
                                                  } />
                                                <aside>
                                                    <label>Weekdays</label>
                                                    <h6>Mon-Fri</h6>
                                                </aside>
                                            </li>
                                            <li>
                                                <input type="radio" name="everyday"  onChange={(e) =>
                                                    handleRadio(e, "laundryService", {title:"Weekends",days:"Sat-Sun"})
                                                  }/>
                                                <aside>
                                                    <label>Weekends</label>
                                                    <h6>Sat-Sun</h6>
                                                </aside>
                                            </li>
                                        </ul>


                                        {errorMsg.laundryService &&
                     (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.laundryService}
                          </p>
                        )}
                                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-10 col-12">
                    <div class="form-group">
                      <h6>Select Time Schedule</h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Specific Time
                            <input type="radio"      onChange={(e) =>
                                handleRadio(e, "selectTimeSchedule", "Specific time")
                              } name="boy" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            Available All Day
                            <input type="radio" name="boy" onChange={(e) =>
                                handleRadio(e, "selectTimeSchedule", "Available All Day")
                              } />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errorMsg.selectTimeSchedule &&
                        !selectTimeSchedule && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.selectTimeSchedule}
                          </p>
                        )}
                    </div>
                    {selectTimeSchedule=="Specific time" && <div class="row">
                      <div class="col-md-6 form-group">
                        <h6>
                          Start Time <span class="required">*</span>
                        </h6>
                        <input value={startTime} name="startTime" onChange={handleChange} type="time" class="form-control" />
                        {errorMsg.startTime &&
                        !startTime && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.startTime}
                          </p>
                        )}
                      </div>
                      <div class="col-md-6 form-group">
                        <h6>
                          End Time <span class="required">*</span>
                        </h6>
                        <input value={endTime} name="endTime" onChange={handleChange} type="time" class="form-control" />

                        {errorMsg.endTime &&
                        !endTime && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.endTime}
                          </p>
                        )}

{errorMsg.endTimeValid &&
                        (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.endTimeValid}
                          </p>
                        )}
                      </div>
                    </div>}

                    <div class="form-group">
                      <h6>Posted By <span class="required">*</span></h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Owner
                            <input
                              checked={postBy=="Owner"?true:false}
                              onChange={(e) =>
                                handleRadio(e, "postBy", "Owner")
                              }
                              type="radio"
                              name="postBy"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            Agent
                            <input
                                                          checked={postBy=="Agent"?true:false}

                              onChange={(e) =>
                                handleRadio(e, "postBy", "Agent")
                              }
                              type="radio"
                              name="postBy"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    {errorMsg.postBy && !postBy&&
                     (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.postBy}
                          </p>
                        )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="Buttons">
            <Link to="/edit-property-5" class="PreviousBtn">
              Previous
            </Link>
            <a
              onClick={handleSubmit}
              class="SaveContinueBtn"
              data-toggle="modal"
              data-target="#MonthlyYearlyModal"
            >
              Finish Pending
            </a>
          </div>
        </div>
      </section>

      <Footer />


      <Modal
        show={SubscriptionModal}
        onHide={handleSubscriptionModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={handleSubscriptionModalClose}
              class="CloseModal"
              data-dismiss="modal"
              style={{cursor:"pointer"}}
            >
              ×
            </a>
            <div class="TableHeader">
                                <ul>
                                    <li>Monthly</li>
                                    <li>
                                        <label class="switch">
                                            <input type="checkbox"/>
                                            <span class="slider"></span>
                                        </label>
                                    </li>
                                    <li>Yearly</li>
                                    <li><button class="OffButton">35% OFF</button></li>
                                </ul>
                            </div>      

                            <div class="MonthYearTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Plan Name</th>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <th><button>{elem?.basicInfo?.planName}</button></th>

                                              )
                                            })}
                                            
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Prices/month</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td> <sub>₹ {elem?.pricingInformation?.MonthlyRate}</sub></td>
                                              )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Number of Properties</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td><sub>{elem?.basicInfo?.NumberOfProperties}</sub></td>
                                              )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Premium Listing</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td><i class={`${elem?.features?.premiumList?"fa-solid fa-check":"fa-solid fa-xmark"}`}></i></td>

                                              )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Verified Property Tag</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td><i class={`${elem?.features?.verifiedTag?"fa-solid fa-check":"fa-solid fa-xmark"}`}></i></td>

                                              )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Email &amp; Social Media Promotion</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td><i class={`${elem?.features?.socialMediaProm?"fa-solid fa-check":"fa-solid fa-xmark"}`}></i></td>
                                              )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Professional Property Photoshoot</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td><i class={`${elem?.features?.profPhotoshoot?"fa-solid fa-check":"fa-solid fa-xmark"}`}></i></td>
                                              )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Higher Position of Your Property in Search Results</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td><i class={`${elem?.features?.higherPosition?"fa-solid fa-check":"fa-solid fa-xmark"}`}></i></td>
                                              )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Property Description by Experts</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td><i class={`${elem?.features?.propertyDesc?"fa-solid fa-check":"fa-solid fa-xmark"}`}></i></td>
                                              )
                                            })}
                                            
                                        </tr>
                                        <tr>
                                            <td>Annual Rate</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                                <td><sub>₹ {elem?.pricingInformation?.AnnualRate}</sub><br /><span>{(((elem?.pricingInformation?.AnnualRate - elem?.pricingInformation?.AnnualDiscountedRate) / elem?.pricingInformation?.AnnualRate) * 100).toFixed(2)}% OFF</span></td>
                                              )
                                            })}
                                            {/* <td>*</td>
                                            <td>$ <sub>10000</sub><br /><span>35% OFF</span></td>
                                            <td>$ <sub>15000</sub><br /><span>35% OFF</span></td>
                                            <td>$ <sub>20000</sub><br /><span>35% OFF</span></td>
                                            <td>$ <sub>30000</sub><br /><span>35% OFF</span></td> */}
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Discounted Annual Rate</td>
                                            {planList?.data?.map((elem,id)=>{
                                              return(
                                              <td><button type="button" onClick={()=>handlePayment(elem)} class="FolderPermissionId">₹ {elem?.pricingInformation?.AnnualDiscountedRate} Buy</button></td>                                              )
                                            })}
                                            
                                            {/* <td><button type="button" class="FolderPermissionId" onClick={()=> {setPayment(true)
                                              setSubscription(false)
                                            }}>$ 1000 Buy</button></td>
                                            <td><button type="button" class="FolderPermissionId" onClick={()=> {setPayment(true)
                                              setSubscription(false)
                                            }}>$ 1500 Buy</button></td>
                                            <td><button type="button" class="FolderPermissionId" onClick={()=> {setPayment(true)
                                              setSubscription(false)
                                            }}>$ 2000 Buy</button></td>
                                            <td><button type="button" class="FolderPermissionId" onClick={()=> {setPayment(true)
                                              setSubscription(false)
                                            }}>$ 3000 Buy</button></td> */}
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={PaymentModal}
        onHide={handlePamentModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={handlePamentModalClose}
              class="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div class="PayNowDetails">
                            <article>
                                <aside>
                                    <div class="PayNowDetailsLeft">
                                        <h3>Book with confidence. Guaranteed.</h3>
                                        <p>You’re covered when you book and pay on Bindle. <a href="javascript:void(0);">Learn more.</a></p>
                                        <ul>
                                            <li><span><img src={require("../assets/images/bell-icon.svg")} /></span>Act fast! Price and availability may change.</li>
                                            <li><span><img src={require("../assets/images/non-refund.svg")} /></span>Non-refundable.</li>
                                            <li><span><img src={require("../assets/images/bell-icon.svg")} /></span>Act fast! Price and availability may change.</li>
                                            <li><span><img src={require("../assets/images/non-refund.svg")} /></span>Non-refundable.</li>
                                            <li><span><img src={require("../assets/images/bell-icon.svg")} /></span>Act fast! Price and availability may change.</li>
                                            <li><span><img src={require("../assets/images/non-refund.svg")} /></span>Non-refundable.</li>
                                            <li><span><img src={require("../assets/images/bell-icon.svg")} /></span>Act fast! Price and availability may change.</li>
                                            <li><span><img src={require("../assets/images/non-refund.svg")} /></span>Non-refundable.</li>
                                        </ul>
                                    </div>
                                </aside>
                                <aside>
                                      <div class="PayNowDetailsRight">
                                        <div class="StandardPlan">
                                            <aside>
                                                <h3>Standard Plan</h3>
                                                <select class="form-control">
                                                    <option>Monthly Fee</option>
                                                </select>
                                            </aside>
                                            <h3>$ 1500</h3>
                                        </div>
                                           <div class="PaymentProtection">
                                               <h5>Payment Protection</h5>
                                               <h6>Book &amp; Pay on Bindle and get:</h6>
                                               <ul>
                                                <li><span><img src={require("../assets/images/comprehensive.png" )} /></span>Comprehensive Payment Protection</li>
                                                <li><span><img src={require("../assets/images/emergency-phone.png")} /></span>Emergency Booking Assistance</li>
                                                <li><span><img src={require("../assets/images/customer-service-.png" )} /></span>24hr Customer Service</li>
                                               </ul>
                                           </div>
                                           <div class="GstArea">
                                            <aside>
                                                <h4>GST (18 %)</h4>
                                                <h3>$ 240</h3>
                                            </aside>
                                              <a href="javascript:void(0);" class="AddGstDetails"> + &nbsp; Add GST Details for Input Credit</a>
                                            <div class="TotalMonthly">
                                                <h6>Total Monthly Fee</h6>
                                                <h2>$ 1740</h2>
                                            </div>
                                            <div class="px-3">
                                                {/* <a onClick={handleComplete} class="PayNowBtn FolderPermissionId" data-toggle="modal" data-target="#Congratulations" data-dismiss="modal">Pay Now</a> */}
                                                <label class="CheckBox"> I accept terms and condition
                                                    <input type="checkbox"/>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                           </div>
                                      </div>
                                </aside>
                            </article>
                        </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProperty6;
